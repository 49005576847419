import { createSlice } from '@reduxjs/toolkit'
import { storage } from '../../Config/config';

const initialState = {
    value: false
};

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        updateModal: (state, action) => {
            state.value = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateModal } = modalSlice.actions

export default modalSlice.reducer