import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { SERVER_URL, frontEnd_API, header } from '../../Config/config'
import { useSelector } from 'react-redux'

function Home() {

  const navigate = useNavigate()

  const storeBanner = useSelector((state) => state.store.value);
  const params = useParams();
  const [productList, setProductList] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const [sorting, setSorting] = useState("id-DESC");
  const [loader, setLoader] = useState(true);

  const getProducts = async () => {
    try {
      const categoryName = params?.categoryName ? params?.categoryName.replace('~', '-').replace('-', ' ') : "";
      console.log("categoryName::", categoryName);

      const { data } = await axios.put(frontEnd_API?.getProducts, {
        category: categoryName,
        sortby: sorting
      }, header)
      setProductList(data?.data);
      console.log("data::", data?.data);
      setLoader(false);
    }
    catch (e) {
      console.log("e::", e);
      setLoader(false);
    }
  }

  useEffect(() => {
    getProducts();
    console.log(header);
    const newList = storeBanner?.storeBannerData.slice(1);
    setBannerList(newList);
    console.log("newList::", newList);
    console.log("params::", params);
  }, [params, sorting])

  return (
    <div className='custom-home-style'>
      {
        (!params?.categoryName) &&
        (storeBanner?.storeBannerData?.length > 0) &&
        < Container className='p-0 my-md-3' style={{ overflow: "hidden" }}>
          <Row className='gy-3 gy-md-3'>
            <Col className='col-12 px-3'>
              <div className='custom-img-holder'>
                <img
                  src={storeBanner?.storeBannerData[0]?.thumbnail}
                  alt={storeBanner?.storeBannerData[0]?.name}
                  onClick={() => navigate(`/category/${storeBanner?.storeBannerData[0]?.categoryName?.replace(/ /g, '-')}`)}
                />
              </div>
            </Col>
            <div className='flex-center-align gap-3 px-3'>
              {
                bannerList?.map((item, index) => (
                  <div
                    className='w-100 custom-img-holder'
                    key={index}
                    onClick={() => navigate(`/category/${item?.categoryName?.replace(/ /g, '-')}`)}
                  >
                    <img src={item?.thumbnail} alt="" />
                  </div>
                ))
              }
            </div>
          </Row>
        </Container>
      }
      <Container className='py-4 py-sm-5'>
        <Row className='gy-4'>
          {/* Heading Part */}
          <Col className='col-12 d-grid d-sm-flex gap-3 justify-content-sm-between align-items-sm-center flex-column-rev'>
            <div className='d-flex d-sm-grid gap-1 justify-content-center'>
              <h4 className='m-0 fw-semibold text-capitalize'>{(params?.categoryName) ? params?.categoryName.replace('~', '-').replace('-', ' ') : "All Products"}</h4>
              <span className='p-color'>{productList?.length} items</span>
            </div>
            <div className='flex-between-align gap-2 justify-content-center'>
              <span className='p-color'>Sort by:</span>
              <select name="" onChange={(e) => {
                console.log("e?.target::", e?.target?.value);
                setSorting(e?.target?.value)
                setLoader(true)
              }} id="" className='py-1 px-2 py-sm-2 px-sm-3'>
                <option value="id-DESC">Newest arrivals</option>
                <option value="price-ASC">Price: low to high</option>
                <option value="price-DESC">Price: high to low</option>
              </select>
            </div>
          </Col>
          {
            loader ?
              <div className='w-100 aspect-3-1 d-grid flex-center-align'>
                <Spinner animation='border' />
              </div>
              : (productList?.length > 0) ?
                productList.map((item, index) => {
                  return (
                    <Col key={index} className='col-6 col-sm-4 col-lg-3'>
                      <div className='custom-img-holder-container d-grid gap-2'>
                        <Link className='custom-img-holder' style={{ backgroundColor: item?.defaultColor ? `${item?.defaultColor}` : 'transparent' }} to={`/product/${item?.id}`}>
                          <img src={item.thumbnail} className='img-one' alt={item?.name} />
                          <img src={item.thumbnailSlide} className='img-two' alt={item?.name} />
                        </Link>
                        <div className='custom-img-cnt d-grid'>
                          <Link className='h5 fw-semibold m-0 fs-sm-12 fs-md-14 fs-lg-16 fs-18' to={`/product/${item?.id}`}>{item?.name}</Link>
                          <span className='fw-bold pt-1 fs-sm-12 fs-md-14 fs-lg-16 fs-18'>
                            ₹{item?.price} <span className='fw-semibold text-decoration-line-through fs-sm-10 fs-md-12 fs-lg-14 fs-16'>₹{item?.mrp}</span>
                          </span>
                        </div>
                      </div>
                    </Col>
                  )
                })
                :
                <h1 className='text-center'>Products not found</h1>
          }
        </Row>
      </Container>
    </div >
  )
}

export default Home
