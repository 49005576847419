import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Accordion, Button, Card, Col, Container, Dropdown, Form, Modal, Row } from 'react-bootstrap'
import { frontEnd_API, header } from '../../Config/config'
import { Link, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import ProductPreviewImage from '../../Components/ProductPreviewImageComponent'
import Swal from 'sweetalert2'
import FormComponent from '../../Components/FormComponent'
import { pattern } from '../../Config/Constant'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { FaArrowLeftLong } from 'react-icons/fa6'
import { BsDownload } from 'react-icons/bs'
import Invoices from '../../Components/Invoices'

function UserSingleOrder() {
    const storeData = useSelector((state) => state.store.value);

    const params = useParams();
    const navigate = useNavigate()
    const [data, setData] = useState();
    const [user, setUser] = useState();
    const [show, setShow] = useState(false);
    const [returnTicket, setReturnTicket] = useState({});
    const [formData, setFormData] = useState({});
    const [error, setError] = useState({});
    const returnReasons = [
        {
            label: "WRONG PRODUCT",
            value: "WRONG-PRODUCT"
        },
        {
            label: "ORDER BY MISTAKE",
            value: "ORDER-BY-MISTAKE"
        },
        {
            label: "DEFECTIVE PRODUCT",
            value: "DEFECTIVE-PRODUCT"
        },
        {
            label: "ARRIVED TOO LATE",
            value: "ARRIVED-TOO-LATE"
        },
        {
            label: "NO LONGER NEED",
            value: "NO-LONGER-NEED"
        }
    ];
    const cancelReasons = [
        {
            label: "Order by mistake",
            value: "ORDER-BY-MISTAKE"
        },
        {
            label: "Change of mind",
            value: "CHANGE-OF-MIND"
        },
        {
            label: "Found a better deal",
            value: "FOUND-A-BETTER-DEAL"
        },
        {
            label: "Incorrect product ordered",
            value: "INCORRECT-PRODUCT-ORDERED"
        },
        {
            label: "Payment issues",
            value: "PAYMENT-ISSUES"
        }
    ];
    const inputFields = [
        {
            divClass: 'col-12',
            name: 'Bank Name',
            key: 'bankName',
            required: true,
            placeholder: 'Your name',
            placeholder: 'Enter Valid Bank Name',
        },
        {
            divClass: 'col-12',
            name: 'Holder Name',
            key: 'accountHolderName',
            required: true,
            placeholder: 'Enter Valid Holder Name',
            placeholder: 'Your name',
        },
        {
            divClass: 'col-12',
            name: 'Account Number',
            key: 'accountNumber',
            placeholder: 'Enter Account No. here...',
            required: true,
        },
        {
            divClass: 'col-12',
            name: 'IFSC Code',
            key: 'ifscCode',
            placeholder: 'Enter IFSC Code here...',
            required: true,
        }
    ]

    const getOrderDetail = async () => {
        try {
            const { data } = await axios.get(`${frontEnd_API.getOrderList}/${params?.orderNumber}`, header)
            console.log("Data::", data);
            setData(data?.data);
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    useEffect(() => {
        getOrderDetail();
    }, [])

    const submitRequest = async () => {
        console.log('118 ln :: ', {
            ...returnTicket,
            ...formData,
            orderId: parseInt(data.id),
            orderPaymentMode: data?.orderPaymentMode,
            orderChildId: data?.orderChildData?.map(e => parseInt(e?.id))
        });
        // return
        if (Object?.keys(error).length === 0 && returnTicket?.description && returnTicket?.returnOrderReason) {
            Swal.fire({
                title: `Are you sure to create request?`,
                text: `This action cannot be undone.`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#12715B',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'No',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    axios.post(frontEnd_API?.returnorder, {
                        ...returnTicket,
                        ...formData,
                        orderId: parseInt(data.id),
                        orderPaymentMode: data?.orderPaymentMode,
                        orderChildId: data?.orderChildData?.map(e => parseInt(e?.id))
                    }, header)
                        .then((res) => {
                            Swal.fire(
                                'Request Created!',
                                'Your request has been created successfully.',
                                'success'
                            );
                            setShow(false);
                            getOrderDetail();
                            console.log("return data::", res);
                        })
                        .catch((err) => {
                            console.log("err::", err);
                            setError(err?.response?.data?.data);
                        })
                }
            });
        }
    }

    const handleChange = (item) => {

        delete error[item?.key];

        // For Chacking Required value
        if (item?.pattern || item?.required) {
            if (item?.required && item?.value?.length <= 0) {
                error[item?.key] = `${item?.name} is required`
            }

            if (item?.pattern && item?.value?.length > 0) {
                const patternCheck = new RegExp(item?.pattern);
                if (!patternCheck.test(item?.value))
                    error[item?.key] = `Invalid pattern for ${item?.name}`;
            }
        }

        // Store Data in formData
        const { key, value } = item
        setFormData((prevData) => ({
            ...prevData,
            [key]: value
        }))

        setError({ ...error });

    }

    const renderValues = (val) => {

        const check = val?.options?.find((e) => e.value === val?.value);
        let checkCity = inputFields?.find((e) => e.name === val?.to);

        checkCity.options = (parseInt(val?.value) !== 0) ? check[val?.from] : [];

        if (formData[val.to]) {
            setFormData((data) => ({
                ...data,
                [val.to]: ''
            }))
        }

    }

    const handleCheckboxChange = (item) => {
        setReturnTicket((prevData) => {
            // Check if orderChildId exists, if not initialize it as an empty array
            const currentOrderChildId = prevData.orderChildId || [];
            const isChecked = currentOrderChildId.includes(item.id);

            return {
                ...prevData,
                orderChildId: isChecked
                    ? currentOrderChildId.filter((id) => id !== item.id) // Remove if already present
                    : [...currentOrderChildId, item.id],                // Add if not present
            };
        });
    };

    const handleCancelOrder = (id) => {
        console.log("header::", header);
        Swal.fire({
            title: `Are you sure you want to cancel this order?`,
            text: `This action cannot be undone`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#12715B',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                // try {
                //     const data = await axios.delete(`${frontEnd_API?.getOrderList}/${id}`, returnTicket, header)
                //     console.log(data?.headers);
                //     Swal.fire("Order Cancelled!", "Your selected order was cancelled successfully.", "success");
                //     navigate('/orders')
                // }
                // catch (e) {
                //     console.log("Err::", e);
                // }
                fetch(`${frontEnd_API?.getOrderList}/${id}`, {
                    method: 'DELETE',
                    headers: header?.headers,
                    body: JSON.stringify(returnTicket)
                })
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire("Order Cancelled!", "Your selected order was cancelled successfully.", "success");
                        navigate('/user/orders')
                    })
                    .catch(error => console.error('Error:', error));

            }
        });
    }

    useEffect(() => {
        console.log("returnTicket::", returnTicket);
    }, [returnTicket])

    const invoiceData = {
        invoiceTitle: "Order Invoice",
        customerName: data?.orderBillingName,
        customerAddress: {
            addressLine1: data?.orderBillingAddressLine1,
            addressLine2: data?.orderBillingAddressLine2,
            area: data?.orderBillingArea,
            city: data?.orderBillingCity,
            state: data?.orderBillingState,
            pinCode: data?.orderBillingPincode,
        },
        customerMobileNumber: data?.userData?.mobile,
        customerEmail: data?.userData?.email,
        customerShippingAddressState: data?.orderShippingCity,
        invoiceNumber: data?.orderNumber,
        purchaseOrderNumber: data?.orderId,
        orderData: data
    }

    return (
        <div className='custom-order-detail'>
            <Container>
                {/* <div className='d-grid'>
                    <div className='d-grid gap-2 gap-lg-0 d-lg-flex justify-content-sm-between align-items-center pt-3'>
                        <div className='d-grid'>
                            <span className='h3 fw-semibold m-0 fs-sm-20 text-capitalize'>{(data?.orderType.toLowerCase() == "customer") ? "" : data?.orderType} Order #{data?.orderNumber}</span>
                            <span className='p-color fs-14 fs-sm-12'>{moment(data?.orderDate).format('MMM D, YYYY h:mm A')}</span>
                        </div>
                        <div className='d-none d-sm-flex gap-2 custom-order-header-btn justify-content-sm-end'>
                            <PDFDownloadLink
                                document={<Invoices data={invoiceData} />}
                                fileName="myDocument.pdf"
                                className='text-decoration-none custom-theme-btn fw-semibold'
                            >
                                {({ blob, url, loading, error }) =>
                                    loading ? 'Loading document...' : <span className='flex-center-align gap-2 fs-14 text-light text-decoration-none'><BsDownload className='me-1' /> Download Invoice</span>
                                }
                            </PDFDownloadLink>
                            <PDFViewer width="100%" height="600">
                                <Invoices data={invoiceData} />
                            </PDFViewer>
                        </div>
                        <div className='d-grid d-sm-none w-100'>
                            <Dropdown className='w-100'>
                                <Dropdown.Toggle variant="light" id="dropdown-basic" className='w-100' style={{ backgroundColor: "white" }}>
                                    More
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='w-100'>
                                    <Dropdown.Item className='d-flex align-items-center gap-2'><BsDownload />Download Invoice</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div> */}
                <Row className='gy-3'>
                    <Col className='col-12 flex-between-align gap-3 custom-order-detail-title'>
                        <div className='d-flex gap-1 gap-md-3 align-items-start flex-wrap'>
                            <div className='d-grid gap-1 gap-md-2'>
                                <h2 className='fw-semibold fs-sm-20 m-0'>Order #{data?.orderNumber}</h2>
                                <h6 className='fs-sm-12'>{moment(data?.orderDate).format('DD, MMM YYYY')}</h6>
                            </div>
                            <span className='custom-order-detail-title-status mt-md-2 py-1 px-2 fw-semibold fs-14 fs-sm-12'>{['seller-accepted', 'cancelled', 'processing']?.includes(data?.orderStatus) ? 'processing' : data?.orderStatus}</span>
                        </div>
                        <div className='d-flex gap-2 custom-order-header-btn justify-content-sm-end'>
                            {/* <PDFDownloadLink
                                document={<Invoices data={invoiceData} />}
                                fileName="myDocument.pdf"
                                className='text-decoration-none custom-theme-btn fw-semibold'
                            >
                                {({ blob, url, loading, error }) =>
                                    loading ? 'Loading document...' : <span className='flex-center-align gap-2 fs-14 text-light text-decoration-none'><BsDownload className='me-sm-1' /> <span className='d-none d-sm-block'>Download Invoice</span></span>
                                }
                            </PDFDownloadLink> */}
                            {/* <PDFViewer width="100%" height="600">
                                <Invoices data={invoiceData} />
                            </PDFViewer> */}
                            {
                                ['delivered']?.includes(data?.orderStatus) && (
                                    <Link
                                        to={data?.orderInvoiceLink}
                                        target='_blank'
                                        className='bg-dark text-white px-3 py-2 fw-semibold text-decoration-none d-flex align-items-center gap-2'
                                    >
                                        <BsDownload className='text-white' />
                                        <span>Download Invoice</span>
                                    </Link>
                                )
                            }
                        </div>
                    </Col>
                    <Col className='col-12 col-md-9 d-grid custom-order-detail-list align-content-start'>
                        {
                            ((data?.returnOrderId > 0 || ['cancelled']?.includes(data?.orderStatus)) && ['COD', 'cod']?.includes(data?.orderPaymentMode)) && (<div className="bg-info py-1 px-3 fs-16 mb-3 rounded-5 fw-semibold">Contact store owner for refund</div>)
                        }
                        <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen className='d-grid gap-3'>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header ><span className='fw-semibold fs-18 fs-md-16 fs-sm-14'>Order Items</span></Accordion.Header>
                                <Accordion.Body className='d-grid gap-3'>
                                    {
                                        (data?.orderChildData?.length > 0) &&
                                        data?.orderChildData?.map((item, index) => {
                                            const colorCode = item?.childOrderVariantData?.filter(e => ['color', 'colors', 'colour', 'colours']?.includes(e?.parentName))?.map(e => e?.variantCode)
                                            return (
                                                <>
                                                    <Row key={index}>
                                                        <Col className='col-4 px-1 px-sm-2 col-sm-3 col-md-2 d-grid'>
                                                            <div className='img-container'>
                                                                <ProductPreviewImage
                                                                    colorCode={colorCode ?? '#ffffff'}
                                                                    isButtonShow={false}
                                                                    objectData={{ preview: item?.productThumbnail || null }}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col className='col-8 px-1 px-sm-2 col-sm-9 col-md-10 d-grid'>
                                                            <div className='d-grid gap-2 gap-sm-3 align-content-between'>
                                                                <div className='d-grid align-content-around gap-1 gap-sm-2 gap-lg-2'>
                                                                    <h5 className='fw-semibold fs-md-14 fs-sm-12 m-0'>{item?.productName}</h5>
                                                                    <div className='d-flex flex-wrap gap-2'>
                                                                        {
                                                                            (item?.childOrderVariantData?.length > 0) &&
                                                                            item?.childOrderVariantData?.map((subItem, subIndex) => (
                                                                                <span
                                                                                    key={subIndex}
                                                                                    className='custom-order-detail-title-status px-2 fs-14 fs-sm-10 fw-semibold text-uppercase'
                                                                                >
                                                                                    {subItem?.variantName}
                                                                                </span>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex align-items-center flex-wrap gap-2 gap-sm-3'>
                                                                    <span className='custom-order-detail-title-status px-2 px-sm-3 py-sm-1 fs-14 fs-sm-12 fw-medium'>{item?.productQuantity} X ₹{parseInt(item?.productPrice)}</span>
                                                                    <span className='custom-order-detail-title-status px-2 px-sm-3 py-sm-1 fs-14 fs-sm-12 fw-bold'>₹{parseInt(item?.productSubTotal)}</span>
                                                                </div>
                                                                {
                                                                    item?.returnOrderNumber?.length > 0 && (
                                                                        <div onClick={() => navigate(`/user/return/${item?.returnOrderNumber}`)} className='pointer px-2 py-1 border border-2 rounded-2 border-danger fw-bold fs-12' style={{ width: 'max-content' }}>{item?.returnOrderNumber}</div>
                                                                    )
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <hr className={`m-0 ${((data?.orderChildData?.length - 1) === index) ? "d-none" : ''}`} />
                                                </>
                                            )
                                        })
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><span className='fw-semibold fs-18 fs-md-16 fs-sm-14'>Order Summary</span></Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col className='col-12 d-grid gap-2 pb-2 pb-sm-3'>
                                            <div className='d-flex gap-2 flex-wrap'>
                                                <span className='custom-order-detail-title-status px-2 px-sm-3 py-sm-1 fs-14 fs-sm-12 fw-semibold text-uppercase'>
                                                    {(data?.orderPaymentMode !== "COD") ? `${data?.orderPaymentModeName}` : 'COD'}
                                                </span>
                                                {(data?.orderPaymentMode !== "COD") &&
                                                    <span className='custom-order-detail-title-status px-2 px-sm-3 py-sm-1 fs-14 fs-sm-12 fw-semibold'>
                                                        Transaction ID: {data?.orderPaymentTransactionNumber}
                                                    </span>
                                                }
                                            </div>
                                            <p className='m-0'>Order details :</p>
                                        </Col>
                                        <Col className='col-12 col-lg-7 d-grid'>
                                            <div>Product</div>
                                        </Col>
                                        <Col className='col-12 col-lg-5 d-grid'>
                                            <div className='flex-between'>
                                                <span>{data?.orderChildData?.length} item</span>
                                                <span>₹{data?.orderSubTotalAmount}</span>
                                            </div>
                                        </Col>
                                        {
                                            (data?.orderCouponAmount != 0) &&
                                            <>
                                                <Col className='col-12 col-lg-7 d-grid'>
                                                    <div>Discount</div>
                                                </Col>
                                                <Col className='col-12 col-lg-5 d-grid'>
                                                    <div className='flex-between'>
                                                        <span>{`${data?.couponCode ? `(${data?.couponCode})` : `-`}`}</span>
                                                        <span>- ₹{data?.orderCouponAmount}</span>
                                                    </div>
                                                </Col>
                                            </>
                                        }
                                        <Col className='col-12 col-lg-7 d-grid'>
                                            <div>Shipping</div>
                                        </Col>
                                        <Col className='col-12 col-lg-5 d-grid'>
                                            <div className='flex-between'>
                                                {/* <span>{(parseInt(data?.orderShippingChargeAmount) == 0) ? "Free Shipping" : ""}</span> */}
                                                <span>Free</span>
                                                <span>- ₹0</span>
                                            </div>
                                        </Col>
                                        <div className='px-2'>
                                            <hr className='my-2' />
                                        </div>
                                        <Col className='col-7 d-grid'>
                                            <div className='fw-bold fs-18'>Total</div>
                                        </Col>
                                        <Col className='col-5 d-grid'>
                                            <div className='flex-between justify-content-end'>
                                                <span className='fw-bold fs-18'>₹{data?.orderNetAmount}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><span className='fw-semibold fs-18 fs-md-16 fs-sm-14'>Order Tracking</span></Accordion.Header>
                                <div className='mb-2 px-4'>
                                    {
                                        data?.awbCode && (
                                            <Link target='_blank' to={`https://shiprocket.co/tracking/${data?.awbCode}`} className='fw-semibold text-primary pointer bg-transparent p-0'>{data?.awbCode}</Link>
                                        )
                                    }
                                </div>
                                <Accordion.Body>
                                    {
                                        (data?.orderTrackingData) &&
                                        <div className='timeline'>
                                            {
                                                (data?.orderTrackingData?.length > 0) &&
                                                data?.orderTrackingData.map((item, index) => {
                                                    return (
                                                        <div key={index} className={`timeline-item ${((data?.orderTrackingData?.length - 1) === index) ? 'lastItem' : ''}`}>
                                                            <div
                                                                className={`timeline-icon circle-one mt-1 ms-1`}
                                                                style={{
                                                                    backgroundColor: (index === 0) ? 'darkblue' : 'grey',
                                                                    outlineColor: (index === 0) ? 'darkblue' : 'grey',
                                                                }}
                                                            ></div>
                                                            <div className='timeline-content'>
                                                                <span className='timestamp'>{moment(item?.updatedAt).format('DD, MMM YYYY h:mm A')}</span>
                                                                <div>
                                                                    <b className='text-capitalize'>{item?.trackingStatus}</b>
                                                                    <span className='description'>{item?.trackingRemark}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                            {
                                // (["pending", "seller-accepted", "cancelled"].includes(data?.orderStatus)) &&
                                (["pending"].includes(data?.orderStatus)) && (
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                            <span className='fw-semibold fs-18 fs-md-16 fs-sm-14 d-flex align-items-center gap-2'>
                                                <i className="bi bi-arrow-counterclockwise"></i>
                                                Order cancel
                                            </span>
                                        </Accordion.Header>
                                        <Accordion.Body className='d-grid'>
                                            <Button variant='danger' className='fw-semibold' onClick={() => setShow(true)}>Cancel order</Button>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            }
                            {
                                (storeData?.isReturnToStore == 1 && ["delivered", "Delivered", "DELIVERED"].includes(data?.orderStatus)) && (
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                            <span className='fw-semibold fs-18 fs-md-16 fs-sm-14 d-flex align-items-center gap-2'>
                                                <i className="bi bi-arrow-counterclockwise"></i>
                                                Order Return
                                            </span>
                                        </Accordion.Header>
                                        <Accordion.Body className='d-grid'>
                                            <Button variant='danger' className='fw-semibold' onClick={() => setShow(true)} disabled={data?.returnOrderId != 0}>{(data?.returnOrderId != 0) ? "Return Request Already Created !" : "Are you sure to create return request ?"}</Button>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            }
                        </Accordion>
                    </Col>
                    <Col className='col-12 col-md-3 d-grid align-items-start align-content-start ps-md-0'>
                        <Card className='bg-transparent border rounded-2 px-3 py-2 mb-3'>
                            <span className='first-capitalize fw-semibold'>{data?.orderStatus?.replace(/-/g, ' ')}</span>
                        </Card>
                        <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen className='d-grid gap-3'>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><span className='fw-semibold fs-18 fs-md-16 fs-sm-14'>Customer</span></Accordion.Header>
                                <Accordion.Body className='d-grid pt-1'>
                                    <div className='d-flex gap-2 fw-semibold fs-sm-14'>
                                        <i className="bi bi-person-circle" />
                                        <span className='first-capitalize'>{data?.userData?.name}</span>
                                    </div>
                                    <div className='d-flex gap-2 fw-semibold fs-sm-14'>
                                        <i className="bi bi-envelope" />
                                        <span className=''>{data?.userData?.email}</span>
                                    </div>
                                    <div className='d-flex gap-2 fw-semibold fs-sm-14'>
                                        <i className="bi bi-telephone-fill" />
                                        <span className='text-capitalize'>{data?.userData?.mobile}</span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><span className='fw-semibold fs-18 fs-md-16 fs-sm-14'>Shipping Address</span></Accordion.Header>
                                <Accordion.Body className='d-grid gap-1 gap-sm-2 pt-1'>
                                    <div className='d-flex gap-2 fs-sm-14'>
                                        <i className="bi bi-person-circle" />
                                        <span className='text-capitalize'>{data?.orderShippingName}</span>
                                    </div>
                                    <div className='d-flex gap-2 fs-sm-14'>
                                        <i className="bi bi-envelope" />
                                        <span>{data?.orderShippingEmail}</span>
                                    </div>
                                    <div className='d-flex gap-2 fs-sm-14'>
                                        <i className="bi bi-telephone-fill" />
                                        <span className='text-capitalize'>{data?.orderShippingMobile}</span>
                                    </div>
                                    <div className='d-flex gap-2'>
                                        <i className="bi bi-geo-alt-fill" />
                                        <p className='m-0 fs-14 fs-sm-12'>{data?.orderShippingAddressLine1}, {data?.orderShippingAddressLine2}, {data?.orderShippingArea}, {data?.orderShippingCity}, {data?.orderShippingState}, {data?.orderShippingCountry} - {data?.orderShippingPincode}</p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><span className='fw-semibold fs-18 fs-md-16 fs-sm-14'>Billing Address</span></Accordion.Header>
                                <Accordion.Body className='d-grid gap-1 gap-sm-2 pt-1'>
                                    <div className='d-flex gap-2 fs-sm-14'>
                                        <i className="bi bi-person-circle" />
                                        <span className='text-capitalize'>{data?.orderBillingName}</span>
                                    </div>
                                    <div className='d-flex gap-2 fs-sm-14'>
                                        <i className="bi bi-envelope" />
                                        <span>{data?.orderBillingEmail}</span>
                                    </div>
                                    <div className='d-flex gap-2 fs-sm-14'>
                                        <i className="bi bi-telephone-fill" />
                                        <span className='text-capitalize'>{data?.orderBillingMobile}</span>
                                    </div>
                                    <div className='d-flex gap-2'>
                                        <i className="bi bi-geo-alt-fill" />
                                        <p className='m-0 fs-14 fs-sm-12'>{data?.orderBillingAddressLine1}, {data?.orderBillingAddressLine2}, {data?.orderBillingArea}, {data?.orderBillingCity}, {data?.orderBillingState}, {data?.orderBillingCountry} - {data?.orderBillingPincode}</p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
            <Modal show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {(["pending", "seller-accepted", "cancelled"].includes(data?.orderStatus)) &&
                            "Order cancel reason"
                        }
                        {(["delivered"].includes(data?.orderStatus)) &&
                            "Create Return Request"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='d-grid gap-3'>
                    {(["delivered"].includes(data?.orderStatus)) &&
                        <>
                            <Card>
                                <Card.Body>
                                    {/* <Card.Title>Select Product :</Card.Title> */}
                                    <Card.Title>Products</Card.Title>
                                    <Card.Title className='pt-2 d-grid gap-4'>
                                        {
                                            (data?.orderChildData?.length > 0) &&
                                            data?.orderChildData?.map((item, index) => {
                                                const colorCode = item?.childOrderVariantData?.filter(e => ['color', 'colors', 'colour', 'colours']?.includes(e?.parentName))?.map(e => e?.variantCode)
                                                return (
                                                    <>
                                                        <Row key={index} className={`align-content-start ${item?.returnOrderChildId > 0 ? 'border rounded-2 border-danger py-2' : ''}`}>
                                                            {/* <Col className='col-4 px-1 col-sm-3 col-md-2 d-grid check-holder' style={{ width: "40px" }}>
                                                                {
                                                                    item?.returnOrderChildId <= 0 ? (
                                                                        <input type="checkbox"
                                                                            className='ui-checkbox'
                                                                            name={item?.label}
                                                                            id={item?.label}
                                                                            value={item?.value}
                                                                            checked={returnTicket?.orderChildId?.includes(item?.id)}
                                                                            onChange={() => handleCheckboxChange(item)}
                                                                        />
                                                                    ) : (<>-</>)
                                                                }

                                                                <label htmlFor={item?.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{item?.label}</label>
                                                            </Col> */}
                                                            <Col className='col-4 p-0 col-sm-3 col-md-2 d-grid' style={{ width: "80px", height: "80px" }}>
                                                                <div className='img-container'>
                                                                    <ProductPreviewImage
                                                                        colorCode={colorCode ?? '#ffffff'}
                                                                        isButtonShow={false}
                                                                        objectData={{
                                                                            preview: item?.productThumbnail || null,
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col className='col-8 px-1 px-sm-2 col-sm-9 col-md-10 d-grid' style={{ width: "calc(100% - 120px)" }}>
                                                                <div className='d-grid gap-2 gap-sm-3 align-content-between ms-2'>
                                                                    <div className='d-grid align-content-around gap-1 gap-sm-2 gap-lg-2'>
                                                                        <h5 className='fw-semibold fs-md-14 fs-sm-12 m-0 fs-16'>{item?.productName}</h5>
                                                                        <div className='d-flex flex-wrap gap-2'>
                                                                            {
                                                                                (item?.childOrderVariantData?.length > 0) &&
                                                                                item?.childOrderVariantData?.map((subItem, subIndex) => (
                                                                                    <span
                                                                                        index={subIndex}
                                                                                        // className='custom-order-detail-title-status px-2 fs-14 fs-sm-10 fw-semibold text-uppercase'
                                                                                        className='border rounded border-2 px-2 fw-semibold text-uppercase fs-12'
                                                                                    >
                                                                                        {subItem?.variantName}
                                                                                    </span>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className='d-flex align-items-center flex-wrap gap-2 gap-sm-3'> */}
                                                                    <div className='d-flex align-items-center justify-content-between'>
                                                                        <span className='custom-order-detail-title-status px-2 px-sm-3 py-sm-1 fs-12 fw-medium'>{parseInt(item?.productQuantity)} x ₹{parseInt(item?.productPrice)}</span>
                                                                        <span className='custom-order-detail-title-status px-2 px-sm-3 py-sm-1 fs-12 fw-bold'>₹{parseInt(item?.productSubTotal)}</span>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <hr className={`m-0 ${((data?.orderChildData?.length - 1) === index) ? "d-none" : ''}`} />
                                                    </>
                                                )
                                            })
                                        }
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Select Reason :</Card.Title>
                                    <Card.Title className='pt-2 d-grid gap-4'>
                                        <Dropdown className='d-grid'>
                                            <Dropdown.Toggle variant="dark" id="dropdown-basic" className='fw-bold'>
                                                {returnTicket?.returnOrderReason ?? "Select Reason"}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className='w-100 fw-semibold'>
                                                {/* <Dropdown.Item>Action</Dropdown.Item> */}
                                                {
                                                    returnReasons.map((item, index) => (
                                                        <Dropdown.Item
                                                            key={index}
                                                            onClick={() => setReturnTicket((prevData) => ({
                                                                ...prevData,
                                                                returnOrderReason: item?.value
                                                            }))}
                                                        >
                                                            {item?.label}
                                                        </Dropdown.Item>
                                                    ))
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <textarea
                                            name=""
                                            id=""
                                            rows={3}
                                            placeholder='Enter Detail Description*'
                                            className='p-2 fs-16 fs-md-14 fs-sm-12 half-border-rad border-color'
                                            onChange={(e) => setReturnTicket((prevData) => ({
                                                ...prevData,
                                                description: e.target.value
                                            }))}
                                        />
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                            {/*
                            {
                                (data?.orderPaymentMode == "COD") &&
                                <>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>Enter Bank Details :</Card.Title>
                                            <Card.Title>
                                                <Form>
                                                    <Row className='pt-3 g-1'>
                                                        {
                                                            inputFields.map((item, index) => {
                                                                const type = item?.type ?? 'text';

                                                                return (
                                                                    <Col className={`px-1 d-grid align-content-start ${(item?.divClass) ?? 'col-12'}`} key={index}>
                                                                        <label htmlFor={item?.key} className='fs-14 fs-sm-12 fw-semibold text-capitalize'>{item?.name}<span className={(item.type !== 'checkbox' ? 'text-danger' : 'd-none')}>{(item?.required) && '*'}</span> </label>
                                                                        {
                                                                            (['select']?.includes(type))
                                                                            &&
                                                                            <>
                                                                                <select
                                                                                    id={item?.key}
                                                                                    className={`text-capitalize ${(item?.type !== 'checkbox') && 'w-100'} p-2 fs-sm-14 seller-box ${(error[item?.key]) && 'border-danger'}`}
                                                                                    name={item?.name}
                                                                                    value={formData[item?.key] ? formData[item?.key] : 0}
                                                                                    onChange={(e) => {
                                                                                        if (item?.onChange) {
                                                                                            renderValues({ ...item?.onChange, value: e.target.value });
                                                                                        }
                                                                                        handleChange({ key: item?.name, value: e.target.value, required: item?.required })
                                                                                    }}
                                                                                    required={item?.required}
                                                                                >
                                                                                    <option value={0}>Select {item?.name}</option>
                                                                                    {
                                                                                        item?.options?.map((opt, subIndex) => {
                                                                                            return (
                                                                                                <option className='text-capitalize' value={opt.value} key={subIndex}>{opt.label}</option>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </select>
                                                                            </>
                                                                        }
                                                                        {
                                                                            (['text', 'checkbox', 'link', 'email', 'number', 'password']?.includes(type))
                                                                            &&
                                                                            <span className={(item.type === 'checkbox') ? 'd-flex align-items-start justify-content-start gap-2' : `${(item?.key === 'mobile') && 'custom-remove-number'} d-block custom-password-input`}>
                                                                                <input
                                                                                    type={(item?.type) ? (item?.type !== 'password') ? item?.type : 'password' : 'text'}
                                                                                    id={item?.key}
                                                                                    placeholder={item?.placeholder ? item?.placeholder : `enter ${item?.name}`}
                                                                                    className={`${(item?.type !== 'checkbox') && 'w-100'} p-2 fs-sm-14 seller-box ${(error[item?.key]) && 'border-danger'}`}
                                                                                    name={item?.name}
                                                                                    value={formData[item?.key] ? formData[item?.key] : ''}
                                                                                    onChange={(e) => handleChange(
                                                                                        {
                                                                                            name: item?.name,
                                                                                            key: item?.key,
                                                                                            value: e.target?.value,
                                                                                            pattern: item?.pattern,
                                                                                            required: item?.required
                                                                                        }
                                                                                    )}
                                                                                    onBlur={(e) => handleChange(
                                                                                        {
                                                                                            name: item?.name,
                                                                                            key: item?.key,
                                                                                            value: e.target?.value,
                                                                                            pattern: item?.pattern,
                                                                                            required: item?.required
                                                                                        }
                                                                                    )}
                                                                                    autoComplete='off'
                                                                                    required={item?.required}
                                                                                />
                                                                                <label htmlFor={item.key} className={(item?.type !== 'checkbox' ? 'd-none' : `${item.checkCSS} w-100`)}>{item?.checkLabel}</label>
                                                                            </span>
                                                                        }
                                                                        {
                                                                            (['textarea']?.includes(type))
                                                                            && <textarea
                                                                                id={item.key}
                                                                                rows={item.areaRow}
                                                                                value={formData[item?.key] && formData[item?.key]}
                                                                                placeholder={item?.placeholder ? item.placeholder : `enter ${item?.name}`}
                                                                                className={`${(item?.type !== 'checkbox') && 'w-100'} p-2 fs-sm-14 seller-box ${(error[item?.key]) && 'border-danger'}`}
                                                                                name={item?.name}
                                                                                onChange={(e) => handleChange(
                                                                                    {
                                                                                        name: item?.name,
                                                                                        key: item?.key,
                                                                                        value: e.target.value,
                                                                                        pattern: item?.pattern,
                                                                                        required: item?.required
                                                                                    }
                                                                                )}
                                                                                onBlur={(e) => handleChange(
                                                                                    {
                                                                                        name: item?.name,
                                                                                        key: item?.key,
                                                                                        value: e.target.value,
                                                                                        pattern: item?.pattern,
                                                                                        required: item?.required
                                                                                    }
                                                                                )}
                                                                                autoComplete='off'
                                                                                required={item?.required}
                                                                            />
                                                                        }
                                                                        <span className='text-danger fs-12 mb-2 custom-error-height fw-semibold'>{error[item?.key]}</span>
                                                                    </Col>
                                                                )
                                                            })
                                                        }
                                                    </Row>
                                                </Form>
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </>
                            }
                            */}
                        </>
                    }
                    {
                        (["pending", "seller-accepted", "cancelled"].includes(data?.orderStatus)) &&
                        <Card>
                            <Card.Body>
                                <Card.Title>Select Reason :</Card.Title>
                                <Card.Title className='pt-2 d-grid gap-4'>
                                    <Dropdown className='d-grid'>
                                        <Dropdown.Toggle variant="dark" id="dropdown-basic" className='fw-bold'>
                                            {returnTicket?.orderCancelReason ?? "Select Reason"}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className='w-100 fw-semibold'>
                                            {/* <Dropdown.Item>Action</Dropdown.Item> */}
                                            {
                                                cancelReasons.map((item, index) => (
                                                    <Dropdown.Item
                                                        key={index}
                                                        onClick={() => setReturnTicket((prevData) => ({
                                                            ...prevData,
                                                            orderCancelReason: item?.value
                                                        }))}
                                                    >
                                                        {item?.label}
                                                    </Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <textarea
                                        name=""
                                        id=""
                                        rows={3}
                                        placeholder='Enter Detail Description*'
                                        className='p-2 fs-16 fs-md-14 fs-sm-12 half-border-rad border-color'
                                        onChange={(e) => setReturnTicket((prevData) => ({
                                            ...prevData,
                                            orderCancelDescription: e.target.value
                                        }))}
                                    />
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={() => setShow(false)}>
                        Cancel
                    </Button> */}
                    {
                        (["delivered"].includes(data?.orderStatus)) &&
                        <Button
                            variant="danger"
                            onClick={submitRequest}
                            type="submit"
                            disabled={Object?.keys(error).length === 0 && returnTicket?.description ? false : true}>
                            Send Request
                        </Button>
                    }
                    {
                        (["pending", "seller-accepted", "cancelled"].includes(data?.orderStatus)) &&
                        <Button
                            variant="danger"
                            type="submit"
                            disabled={returnTicket?.orderCancelReason && returnTicket?.orderCancelDescription ? false : true}
                            onClick={() => handleCancelOrder(data?.id)}
                        >
                            Confirm
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default UserSingleOrder