import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import FormComponent from '../../Components/FormComponent'
import { pattern } from '../../Config/Constant'
import { frontEnd_API, header } from '../../Config/config'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

function UserAddressPage() {

  const user = useSelector((state) => state.user.value)
  const [data, setData] = useState([]);
  const [allAddress, setAllAddress] = useState([]);
  const [updateAddress, setUpdateAddress] = useState();
  const [storeAddressID, setStoreAddressID] = useState();

  useEffect(() => {
    axios.get(frontEnd_API.getGlobal, header)
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        console.log("err::", err);
      })

    axios.put(frontEnd_API.getAddress, user, header)
      .then((res) => {
        setAllAddress(res?.data?.data);
        console.log("res::", res?.data);
      })
      .catch((err) => {
        console.log("err::", err);
      })

  }, [user])

  const handleAddressLoad = () => {
    axios.put(frontEnd_API.getAddress, user, header)
      .then((res) => {
        setAllAddress(res?.data?.data);
      })
      .catch((err) => {
        console.log("err::", err);
      })
    setUpdateAddress();
    setStoreAddressID();
  }

  const handleDelete = (item) => {
    if (window.confirm(`Are you sure to delete ${item.name}'s Address?`)) {
      axios.delete(`${frontEnd_API.deleteAddress}/${item.id}`, header)
        .then(() => {
          handleAddressLoad();
          toast("Address Deleted Successfully !")
        })
        .catch((e) => {
          console.log("e::", e);
        })
    }
  }

  const hanldeUpdateAddress = (item) => {
    if (window.screen.width < 768) {
      window.scrollTo({
        top: 125,
        behavior: 'smooth'
      });
    }
    else {
      window.scrollTo({
        top: 40,
        behavior: 'smooth'
      });
    }
    setStoreAddressID(item?.id);

    delete item?.id;
    delete item?.userId;
    delete item?.status;
    delete item?.updatedAt;
    console.log(item);
    setUpdateAddress(item);
  }

  const handleChange = (item) => {
    if (window.confirm(`Are you sure to set ${item?.name}'s Address as a Primary?`)) {
      axios.put(`${frontEnd_API.primaryAddress}/${item.id}`, item, header)
        .then(() => {
          handleAddressLoad();
          toast("Primary Address Changed !")
        })
        .catch((e) => {
          console.log("e::", e);
        })
    }
  }

  return (
    <div className='user-address'>
      <h4 className='p-0'>Address</h4>
      <Row className='g-4 pt-3'>
        <Col xs='12' lg='7'>
          <div className='default-white-box p-3'>
            <div className='d-grid align-content-start custom-login-form'>
              <h5 className='fs-18 m-0'>New Address</h5>
              <FormComponent
                action={frontEnd_API.postAddress}
                onSubmit={handleAddressLoad}
                actionType={`${(updateAddress) ? 'put' : 'post'}`}
                actionValue={(storeAddressID) && storeAddressID}
                submitFullWidth={false}
                valueData={(updateAddress) && updateAddress}
                submitBTNAlign={'justify-content-start'}
                formMessage={(updateAddress) ? "Address Updated !" : "New Address Added !"}
                button={(updateAddress) ? "Update" : "Add"}
                fields={[
                  {
                    divClass: 'col-12',
                    name: 'Name',
                    key: 'name',
                    required: true,
                  },
                  {
                    type: 'email',
                    divClass: 'col-12 col-md-6',
                    name: 'Email',
                    key: 'email',
                    required: true,
                    pattern: pattern.email
                  },
                  {
                    type: 'number',
                    divClass: 'col-12 col-md-6',
                    name: 'Mobile',
                    key: 'mobile',
                    required: true,
                    pattern: pattern.mobile
                  },
                  {
                    divClass: 'col-12 col-md-6',
                    name: 'Address Line 1',
                    key: 'addressLine1',
                    required: true,
                  },
                  {
                    divClass: 'col-12 col-md-6',
                    name: 'Address Line 2',
                    key: 'addressLine2',
                  },
                  {
                    type: 'select',
                    options: data,
                    divClass: 'col-6 col-lg-4',
                    name: 'state',
                    key: 'state',
                    required: true,
                    onChange: {
                      from: 'children',
                      to: 'city',
                      options: data
                    }
                  },
                  {
                    divClass: 'col-6 col-lg-4',
                    type: 'select',
                    name: 'city',
                    key: 'city',
                    required: true,
                    options: ((updateAddress) && data.filter(item => item.value === updateAddress?.state)[0]?.children)
                  },
                  {
                    divClass: 'col-12 col-lg-4',
                    name: 'Pincode',
                    key: 'pinCode',
                    required: true,
                  },
                ]}
              />
            </div>
          </div>
        </Col>
        <Col xs='12' lg='5' className={`d-grid gap-3 align-content-start ${(!allAddress.length) && 'align-content-center'}`}>
          {
            (!allAddress.length)
              ? <h4 className='fw-semibold text-center d-grid gap-2'><i className="bi bi-house-door color-info" style={{ fontSize: '50px' }} /> No address data found !</h4>
              :
              allAddress.map((item, index) => {
                return (
                  <div key={index} className='user-address-list d-grid'>
                    <label htmlFor={item?.id} className={`user-address-list-input p-3  d-flex gap-1 gap-sm-2 ${(item?.isPrimary === 1) && 'user-address-list-input-primary'}`}>
                      <input type="radio" id={item?.id} name='primary'
                        onChange={() => handleChange(item)} checked={(item?.isPrimary === 1) && true} />
                      <label htmlFor={item?.id}>{(item?.isPrimary === 1) ? 'Primary Address' : 'Set As Primary'}</label>
                    </label>

                    <hr className='m-0 p-0' />

                    <div className='d-grid gap-1 fs-12 p-3 py-2'>
                      <div className='user-address-list-detail d-flex gap-1 gap-sm-2'>
                        <i className="bi bi-person-circle"></i>
                        <span className='text-capitalize'>{item?.name}</span>
                      </div>
                      <div className='user-address-list-detail d-flex gap-1 gap-sm-2'>
                        <i className="bi bi-telephone-fill"></i>
                        <span>{item?.mobile}</span>
                      </div>
                      <div className='user-address-list-detail d-flex gap-1 gap-sm-2'>
                        <i className="bi bi-envelope"></i>
                        <span>{item?.email}</span>
                      </div>
                      <div className='user-address-list-detail d-flex gap-1 gap-sm-2 pt-2'>
                        <i className="bi bi-geo-alt-fill"></i>
                        <address className='m-0'>{item?.addressLine1}, {item?.addressLine2} {item?.city}, {item?.state} - {item?.pinCode}</address>
                      </div>
                    </div>

                    <hr className='m-0' />

                    <div className={`user-address-list-btn input d-flex justify-content-start gap-3 px-3 py-2 ${(item.isPrimary === 1) && 'user-address-list-btn-primary'}`}>
                      <Link className='d-flex align-items-center gap-1 custom-edit-btn'
                        onClick={() => hanldeUpdateAddress(item)}>
                        <i className="bi bi-pen"></i>
                      </Link>
                      <Link className='d-flex align-items-center gap-1 custom-trash-btn'
                        onClick={() => handleDelete(item)}>
                        <i className="bi bi-trash"></i>
                      </Link>
                    </div>

                  </div>
                )
              })
          }
        </Col>
      </Row>
    </div>
  )
}

export default UserAddressPage
