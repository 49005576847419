import { createSlice } from '@reduxjs/toolkit';
import { storage } from '../../Config/config';

const initialState = {
    value: JSON.parse(localStorage.getItem(storage.user)) || null
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        storeUser: (state, action) => {
            state.value = action.payload;
            localStorage.setItem(storage.user, JSON.stringify(state.value));
        },
        updateUser: (state, action) => {
            state.value = {
                ...state.value,
                ...action.payload,
            };
            localStorage.setItem(storage.user, JSON.stringify(state.value));
        },
        removeLoggedUser: (state) => {
            state.value = null;
            localStorage.removeItem(storage.user);
        },
    },
});

// Action creators are generated for each case reducer function
export const { storeUser, updateUser, removeLoggedUser } = userSlice.actions;

export default userSlice.reducer;
