import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { frontEnd_API, header } from '../../Config/config';
import img404 from '../../Asstes/Images/not-found.png'

function Pages() {
    
    const navigate = useNavigate()
    const params = useParams();

    const [pageObject, setPageObject] = useState();

    const getPageDetail = async () => {
        try {
            const { data } = await axios.get(`${frontEnd_API.getSingleInformativePage}/${params?.slug}`, header)
            console.log("data::", data);
            setPageObject(data?.data);
        }
        catch (e) {
            console.log("err::", e);
        }
    }

    useEffect(() => {
        getPageDetail();
    }, [params])
    return (
        <div>
            <Container>
                <div className='py-2 d-flex flex-column gap-2'>
                {
                    (pageObject?.description) ?
                     <div dangerouslySetInnerHTML={{ __html: pageObject?.description }}></div> 
                    :
                    <div className='py-5 mt-5 d-flex flex-column align-items-center h-100 w-100 justify-content-center'>
                        <img src={img404} height={300} />
                        <div
                            className='pointer border rounded bg-dark px-2 py-1 text-white mt-5'
                            onClick={() => navigate('/home')}
                        >
                            Back to home
                        </div>
                    </div>
                }
                </div>
            </Container>
        </div>
    )
}

export default Pages