import React from 'react'

function ReturnPolicy() {
    return (
        <div>
            <div className='container cs-container-xl' style={{ paddingTop: "3rem", paddingBottom: "3rem" }}>
                <div className='row child-cols'>
                    <div className='col-md-4 d-none d-md-grid sidebar-list'>
                        <div className=''>
                            <ol className='position-sticky theme-color my-pages-content fs-14 start-0 d-grid gap-3' style={{ top: "65px", listStyleType: "upper-alpha" }}>
                                <li className='pointer'>
                                    <a href="#p-a" className='text-decoration-none'>Courier Returns</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-b" className='text-decoration-none'>Customer Returns</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-c" className='text-decoration-none'>Cancellations</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-d" className='text-decoration-none'>Conclusion</a>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className='col-12 col-md-8 d-grid align-content-start'>
                        <div className="section">
                            <div className="page-wrap panel vstack gap-4 lg:gap-6 xl:gap-8">
                                <div className="page-wrap panel vstack gap-4 lg:gap-6 xl:gap-8">
                                    <section className='d-grid gap-3 gap-sm-4'>
                                        <h1 className='m-0'>Refund and Return Policy</h1>
                                        <address className='m-0 p-text'>
                                            <strong className='fw-semibold'>PRINTFUSE GLOBAL PRIVATE LIMITED</strong> <br />
                                            Ground Floor Plot No 21 <br />
                                            Sec-142 Noida <br />
                                            Uttar Pradesh India 201305
                                        </address>
                                        <span className='p-text'>Last updated: 1st October 2024</span>
                                    </section>
                                    <div className="page-content panel fs-6 md:fs-5">
                                        <section id="returns-cancellations-refunds">
                                            <h2>Reasons for Returns</h2>

                                            <h3 id="p-a">1. Courier Returns</h3>
                                            <p>Courier returns occur when an order is undelivered and marked as ‘Return to Origin’ (RTO). Possible reasons for an order being marked as RTO include:</p>
                                            <ul>
                                                <li>Customer not contactable</li>
                                                <li>Incomplete address</li>
                                                <li>COD amount not ready</li>
                                                <li>Future delivery requested by the customer</li>
                                                <li>Self-pickup requested by the customer</li>
                                                <li>Customer refused delivery</li>
                                                <li>Door/premises/office closed</li>
                                                <li>Pin code not serviceable</li>
                                            </ul>

                                            <h4>Provider Receives the RTO</h4>
                                            <p>When the provider receives the returned shipment, it will be updated in your seller account, and you will receive an email notification at the registered address.</p>

                                            <h4>Action Required on Courier Returned Orders</h4>
                                            <p>Provider will store returned orders for 60 days from the date marked as RTO, without any warehousing fee. During this period, you can choose to reship the order to yourself or another address. After 60 days, we will allow you to reship the products at your address within 3 working days else we will auto reship them and balance will be deducted from your wallet. In case your wallet doesn’t have the balance, we will donate the products to NGOs.</p>
                                            <p>If the pin code becomes unserviceable after the order is placed, we will not be responsible for the RTO, and no refunds will be processed.</p>

                                            <h3 id="p-b">2. Customer Returns</h3>
                                            <p>Customer returns involve issues with product quality or requests for exchanges:</p>
                                            <ul>
                                                <li><strong>Product Quality Issues:</strong> For misprinted, damaged, or defective items, you must notify Printfuse within 15 days of delivery. Claims for items lost in shipment must also be reported within 15 days of the estimated delivery date.</li>
                                                <li><strong>Broken Products:</strong> For fragile items like mugs and framed posters, issues must be raised within 15 days of delivery. To initiate a replacement request, provide pictures of the broken product along with the original packaging, clearly showing the shipping label and customer details for verification.</li>
                                                <li><strong>Rejection by Customer:</strong> As products are customized to your specifications, returns or rejections must be handled by you. No refunds will be issued for modifications in size or color. A new order must be placed at your expense for any modifications.</li>
                                            </ul>

                                            <h4>Action on Returned Orders</h4>
                                            <p>Orders returned due to incorrect addresses or customer rejections will be marked as RTO in the Seller panel upon receipt. These returns will be stored free of charge for 60 days. After 60 days, you can reship the products to your address within 3 working days, or we will auto reship them and deduct the balance from your wallet. If your wallet doesn’t have the balance, the products will be donated to NGOs.</p>

                                            <h2 id="p-c">Cancellations</h2>

                                            <h3>1. Customer-Initiated Cancellations</h3>
                                            <p>If we receive a cancellation notice and the order has not yet been processed or shipped, we will cancel the order immediately and refund the full amount as credits to your seller account. If the order has already been shipped, cancellation is not possible, and the product will follow the normal lifecycle process.</p>

                                            <h3>2. Provider-Initiated Cancellations</h3>
                                            <p>Under certain circumstances, the provider may need to cancel an order. This may happen due to limitations on quantities, inaccuracies in product or pricing information, or issues identified by our fraud prevention team. If your order is canceled after payment, the amount will be credited back to your seller account.</p>

                                            <h3>Manner of Refund</h3>
                                            <p>If a cancellation is approved, refunds will be issued in the form of credits that can be used for future orders. Customers agree not to dispute the decisions made by Printfuse regarding cancellations.</p>

                                            <h2 id="p-d">Conclusion</h2>
                                            <p>Returns can be classified into two segments: Courier and Customer Returns. This policy outlines the processes involved in managing each type of return.</p>

                                            <p>For further assistance, please contact us at <a href="mailto:support@printfuse.in">support@printfuse.in</a>.</p>

                                            <p>Thank you for choosing Printfuse!</p>
                                        </section>
                                    </div>
                                    <div className="page-footer panel">
                                        <p className="fs-7 opacity-60 m-0">Last updated: 1st October 2024</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReturnPolicy