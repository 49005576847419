import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import FormComponent from '../../Components/FormComponent'
import { frontEnd_API, header } from '../../Config/config'
import { updateUser } from '../../Store/Slices/userSlice'
import axios from 'axios'
import { pattern } from '../../Config/Constant'

function UserAccountDetail() {
  const [val, setVal] = useState([]);

  useEffect(() => {
    axios.get(frontEnd_API.getProfile, header)
      .then((res) => {
        setVal(res?.data?.data);
        console.log(res?.data?.data);
      })
      .catch((err) => {
        console.log("err::", err);
      })
  }, [])

  const updateProfile = () => {
    axios.get(frontEnd_API.getProfile, header)
      .then((res) => {
        setVal(res?.data?.data);
      })
      .catch((err) => {
        console.log("err::", err);
      })
  }

  return (
    <div>
      <h4 className='p-0 fw-semibold'>Account Details</h4>
      <Row className='g-4 pt-3'>
        <Col className='col-12 col-lg-8'>
          <div className='default-white-box p-3'>
            <div className='d-grid align-content-start custom-login-form'>
              <h5 className='fs-18 m-0'>Contact Information</h5>
              <FormComponent
                action={frontEnd_API.postUpdateProfile}
                dispatchRedux={updateUser}
                valueData={val}
                submitFullWidth={false}
                submitBTNAlign={'justify-content-center'}
                onSubmit={updateProfile}
                formMessage={"User Details Updated !"}
                button={"Update"}
                fields={[
                  {
                    divClass: 'col-12',
                    name: 'name',
                    key: 'name',
                    required: true,
                  },
                  {
                    divClass: 'col-12',
                    name: 'email',
                    key: 'email',
                    placeholder: 'sample@gmail.com',
                    required: true,
                    pattern: pattern.email,
                  },
                  {
                    divClass: 'col-12',
                    name: 'mobile',
                    key: 'mobile',
                    type: 'number',
                    placeholder: 'Enter 10 digit Mobile number',
                    required: true,
                    pattern: pattern.mobile,
                  }
                ]}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default UserAccountDetail
