import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function StoreNotFound() {
    return (
        <div className='custom-store-not-found'>
            <Container>
                <Row className='pf-flex-sm-col-reverse'>
                    <Col className='col-12 col-sm-8 d-grid gap-3 align-content-center text-center text-sm-start'>
                        <h2 className='fw-bold m-0'>Store Not Available</h2>
                        <p className='m-0'>The store you are looking for is either nonexistent or has been removed. Please verify that the URL is accurate.</p>
                        <Link to={`https://printfuse.in/`}>Go to printfuse.in</Link>
                    </Col>
                    <Col className='col-12 col-sm-4 d-grid px-5 p-sm-2'>
                        <div className='img-holder'>
                            <img src={require('../Asstes/Images/no-data.png')} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default StoreNotFound
