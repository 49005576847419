import React from 'react'

function IntellectualPolicy() {
    return (
        <div>
            <div className='container cs-container-xl' style={{ paddingTop: "3rem", paddingBottom: "3rem" }}>
                <div className='row child-cols'>
                    <div className='col-md-4 d-none d-md-grid sidebar-list'>
                        <div className=''>
                            <ol className='position-sticky theme-color my-pages-content fs-14 start-0 d-grid gap-3' style={{ top: "65px", listStyleType: "upper-alpha" }}>
                                <li className='pointer'>
                                    <a href="#p-a" className='text-decoration-none'>Definitions</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-b" className='text-decoration-none'>General</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-c" className='text-decoration-none'>When Does This IPP Apply?</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-d" className='text-decoration-none'>Can This IPP Be Modified?</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-e" className='text-decoration-none'>How Does Printfuse Handle Intellectual Property Right Claims?</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-f" className='text-decoration-none'>What Can You Do If Your Content Was Removed from Printfuse?</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-g" className='text-decoration-none'>What Are You Agreeing to Under This IPP?</a>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className='col-12 col-md-8 d-grid align-content-start'>
                        <div className="section">
                            <div className="page-wrap panel vstack gap-4 lg:gap-6 xl:gap-8">
                                <div className="page-wrap panel vstack gap-4 lg:gap-6 xl:gap-8">
                                    <section className='d-grid gap-3 gap-sm-4'>
                                        <h1 className='m-0'>Intellectual Property Policy</h1>
                                        <address className='m-0 p-text'>
                                            <strong className='fw-semibold'>PRINTFUSE GLOBAL PRIVATE LIMITED</strong> <br />
                                            Ground Floor Plot No 21 <br />
                                            Sec-142 Noida <br />
                                            Uttar Pradesh India 201305
                                        </address>
                                        <span className='p-text'>Last updated: 1st October 2024</span>
                                    </section>
                                    <div className="page-content panel fs-6 md:fs-5">

                                        <section>
                                            <h2>Intellectual Property Policy</h2>
                                            <p><strong>Last updated:</strong> 1st October 2024</p>

                                            <p>This Intellectual Property Policy (the “IPP”) outlines the responsibilities and rights of registered and non-registered users (each a “User,” and collectively, the “Users”) of our applications, software, products, and services (collectively, the “Service”) with regard to the intellectual property of Users and of Printfuse (“Printfuse”). This document is an important contract between Printfuse and our Users, and as such, we have tried to make it as clear as possible. In addition to the full legal terms and text, we have provided short non-binding summaries with each clause. These summaries do not aim to replace or misrepresent the full text. For the avoidance of doubt, “intellectual property” refers to copyrights, patents, patent disclosures and inventions (whether patentable or not), trademarks, service marks, trade secrets, know-how and other confidential information, trade dress, trade names, logos, corporate names, and all other such rights.</p>

                                            <h3 id="p-a">A. Definitions</h3>
                                            <p><strong>Summary:</strong> These terms are used throughout this IPP and it’s important that you understand what we are referring to when we use them.</p>

                                            <p>Terms not defined in this section shall have the meaning provided elsewhere in the Terms of Service.</p>
                                            <ul>
                                                <li><strong>“Agreement”</strong> refers, collectively, to all the terms, conditions, notices contained or referenced in this document (the “IPP”) and all other operating rules, policies, including the Printfuse Privacy Policy and the Printfuse Terms of Service and procedures that we may publish from time to time on the Website including but not limited to Shipping, Payments, and Returns.</li>
                                                <li><strong>“Content”</strong> refers to content featured or displayed through the Website, including without limitation text, data, articles, images, photographs, graphics, software, applications, designs, features, and other materials that are available on the Website or otherwise available through the Service. “Content” also includes Services. “User-Generated Content” is Content, written or otherwise, created or uploaded by our Users. “Your Content” is Content that You create or own, otherwise, Printfuse manages and retains rights to the Content.</li>
                                                <li><strong>“Customers”</strong> refer to individuals or companies that purchase Your Products in Your Sales Channel.</li>
                                                <li><strong>“Order”</strong> refers to Your Product once it has been purchased by You or Your Customers. “Produced Orders” refers to Orders that have been completed and shipped.</li>
                                                <li><strong>“Products”</strong> refers to the all products offered by the different Providers on our Website, including, but not limited to clothing, accessories, home decor, or kitchenware. “Your Products” refers to Products you create by adding Your Content using our Service. “Your Mockups” are visual representations of Your Products created by Our Service.</li>
                                                <li><strong>“Providers”</strong> refers to the organizations and companies that Printfuse partners with in the scope of its Service to offer Products to You.</li>
                                                <li><strong>“Sales Channels”</strong> or “Stores” refers to the other websites and platforms that You use to sell Your Products, including but not limited to Shopify, Woocommerce, or RESTAPI.</li>
                                                <li><strong>“Us” and “We”</strong> refers to Printfuse, as well as our affiliates, directors, subsidiaries, contractors, licensors, officers, agents, and employees.</li>
                                                <li><strong>“User,” “You,” “Your”</strong> refers to the individual person, company, or organization that has visited or is using the Website or Service; that accesses or uses any part of the account; or that directs the use of the account in the performance of its functions.</li>
                                                <li><strong>“Website”</strong> refers to Printfuse’s website located at Printfuse.com, and all content, services and products provided by Printfuse at or through the Website. It also refers to Printfuse-owned subdomains such as app.Printfuse.in. These Terms also apply to Printfuseapp.com and all subdomains of Printfuseapp.com such as app.Printfuseapp.com. These terms also govern the use of Printfuse plugins such as those available in the Shopify App, Woocommerce, or those we will create in the future. Occasionally, Printfuse plugin pages and websites owned by Printfuse may provide additional or different terms of service. If those additional terms conflict with this IPP, the terms in the additional terms with regard to the matter in conflict shall apply.</li>
                                            </ul>

                                            <h3 id="p-b">B. General</h3>
                                            <p>When You use Our Services and Content, You are agreeing to:</p>
                                            <ul>
                                                <li>This Intellectual Property Policy</li>
                                                <li>Our Privacy Policy</li>
                                                <li>Our Terms of Service</li>
                                                <li>Any other terms or policies we reference in any of the above.</li>
                                            </ul>

                                            <p>Except as they may be supplemented by additional terms and conditions, policies, guidelines or standards, this IPP constitutes the entire Agreement between Printfuse and You pertaining to the subject matter hereof, and supersedes any and all prior oral or written understandings or agreements between Printfuse and You in relation to the access to and use of the Service.</p>

                                            <p>If any provision of this IPP is held to be invalid or unenforceable, such provision will be struck and will not affect the validity and enforceability of the remaining provisions.</p>

                                            <h3 id="p-c">C. When Does This IPP Apply?</h3>
                                            <p><strong>Summary:</strong> By using Printfuse, You are agreeing to all the terms below.</p>

                                            <p>By using the Service, You are agreeing, on behalf of Yourself and those You represent, to comply with and be legally bound by this Agreement and all applicable laws. If You do not agree with any provision of this IPP, You must discontinue the registration process, discontinue Your use of the Service, and, if You are already registered, delete Your account.</p>

                                            <h3 id="p-d">D. Can This IPP Be Modified?</h3>
                                            <p><strong>Summary:</strong> We can change these terms, and if the changes are big, we’ll let You know.</p>
                                            <p>Printfuse reserves the right to revise this IPP from time to time and the most current version will always be posted on our Website. If a revision, in our sole discretion, is material we will notify You. The “Last Updated” date at the top of this IPP reflects the date of the last modification. Your use of the Service following the effective date of any modifications to this IPP will constitute your acceptance of such revised IPP. If You do not agree to the new terms, You should stop using the Service and delete Your account.</p>

                                            <h3 id="p-e">E. How Does Printfuse Handle Intellectual Property Right Claims?</h3>
                                            <p>Printfuse respects the intellectual property rights of others and we expect people using our Service to do the same. It is our practice—in appropriate circumstances and at our discretion—to either disable or terminate the accounts of Users who infringe or are repeatedly charged with infringing copyrights or other intellectual property rights of others.</p>
                                            <p>Printfuse prohibits the use of our Service to sell counterfeit goods. A User printing and selling counterfeit goods through our Service may be held liable for selling counterfeit products if such User has reason to know or knows that the products are counterfeit.</p>
                                            <p><strong>Summary:</strong> We’re passionate about helping people run their own businesses and as such we feel strongly about other people getting in the way of that by stealing intellectual property.</p>
                                            <p>Printfuse allows Users to upload Content to our platform to create User Products to sell. Subject to the terms outlined in the Printfuse Terms of Service, Users alone are solely responsible for such User’s Content. However, We retain the right, at our sole discretion, to reject the production of User Products that contain Content that we have reason to believe may be infringing copyrights or other intellectual property rights of others.</p>
                                            <p><strong>Summary:</strong> Although You alone are responsible for the content You upload, we can refuse to produce any products with content we believe might be infringing on others’ rights.</p>
                                            <p>If You believe that the Content in question does not infringe on intellectual property rights of others, You should contact Printfuse Customer Care with proof clearly outlining Your rights to use the Content.</p>
                                            <p>If You notice infringement of Your copyrights or other intellectual property rights on Printfuse, You can notify Us as follows. Please note that Printfuse cannot provide You with legal advice or representation, and We recommend that You speak with a licensed attorney in Your jurisdiction if You are seeking legal advice. If You are a copyright holder or are authorized to act on behalf of one, You may report alleged copyright infringements on the Service by completing the Digital Millennium Copyright Act (“DMCA”) Notice of Alleged Infringement (the “DMCA Notice”). In accordance with the Digital Millennium Copyright Act, which You can find on the US Copyright Office website, We will respond as quickly as we can to claims of suspected copyright infringement that are reported via our DMCA Notice of Alleged Infringement or to support@printfuse.in.</p>
                                            <p>Upon receipt of Your DMCA Notice, We will notify the User responsible for uploading the corresponding Content and will provide the User with an opportunity to respond. We will take whatever additional action we deem appropriate, which may include removing the reported content from Printfuse. Please note that it may take up to 10 business days to process, review, and act on Your request.</p>
                                            <p><strong>Summary:</strong> Printfuse respects intellectual property rights. If We receive a DMCA Notice, We may disable access or remove the allegedly infringing content from Printfuse Stores. If Your Content is removed and You think the claim is invalid, You can respond and let us know why. If You believe one of our Users is infringing on Your intellectual property rights, please send Printfuse a DMCA Notice. We may remove the content and will certainly notify the Merchant.</p>
                                            <p>Printfuse respects the trademark rights of others. Accounts with usernames, board names, or any other content that may mislead or violate another’s trademark will need to be updated or may be permanently suspended.</p>
                                            <p>If You are concerned that someone may be using Your trademark in an infringing way on our Website, let Us know by writing to support@printfuse.in. We will review Your complaint and take whatever action We deem appropriate, which may include temporary or permanent removal of the Content in question.</p>
                                            <p><strong>Summary:</strong> Printfuse also takes issues of trademark violations seriously and if We receive a complaint of a purported trademark violation, We will investigate and may remove potentially infringing content.</p>

                                            <h3 id="p-f">F. What Can You Do If Your Content Was Removed from Printfuse?</h3>
                                            <p><strong>Summary:</strong> If You believe the complaint raised regarding Your Content is improper, follow the proper instructions below and We will help You sort things out.</p>
                                            <p>If You received a notification that Your Content has been removed as a result of a copyright complaint, it means another User reported that they own the rights to the Content, and requested that We remove Your Content. If You want us to forward the information from the copyright complaint notification, email us at support@printfuse.in to let us know. Please note that We may remove some personal contact information from the original complaint.</p>
                                            <p>If Your Content is the subject of repeated copyright complaints, We may disable Your account altogether.</p>
                                            <p>If You think We made a mistake by removing Your Content, You can file a counter-notice with us, by following the directions and requirements below. When We get a counter-notice that includes all of the information required, We will remove the complaint from Your account’s record.</p>
                                            <p>Note: There are legal and financial consequences for fraudulent or bad faith counter-notices. Before submitting a counter-notice, make sure You have a good faith belief that We removed Your Content in error, and that You understand the repercussions of submitting a false claim. If Printfuse is the subject of a legal cause of action on account of your false claim, You will be added to the claim and Printfuse will not be liable for such false claim. In addition, Printfuse may at its sole discretion delete Your account and refuse future service, which includes cancelling any orders that are active at the time, with no refund to you.</p>
                                            <p>To submit Your counter-notice, email us at support@printfuse.in and include all of the following information:</p>
                                            <ul>
                                                <li>Your name, address and phone number.</li>
                                                <li>The DMCA ID found at the bottom of the notification email You received from us.</li>
                                                <li>The web address of the Content We removed (copy and paste the link from the notification email).</li>
                                                <li>A statement under penalty of perjury that You have a good faith belief that Your Content was removed in error.</li>
                                                <li>A statement that You consent to the jurisdiction of Court for the judicial district in which your address is located or, if Your address is outside of India, for any judicial district in which Printfuse may be found, and that You agree to accept service of process from the entity who provided the original complaint.</li>
                                                <li>A physical or electronic signature (for example, by typing Your full name).</li>
                                                <li>Any additional information you have showing that You own or are otherwise entitled to use the Content at issue.</li>
                                            </ul>
                                            <p>If You received a trademark complaint notification from Us, You can contest it by emailing support@printfuse.in and letting Us know why You think the complaint is invalid.</p>

                                            <h3 id="p-g">G. What Are You Agreeing to Under This IPP?</h3>
                                            <p>By uploading Your Content on the Service, You represent and warrant that: (i) You either own the Content posted by You on or through the Service or that You otherwise have the legal right and authority to use Your Content; (ii) the posting and use of Your Content on or through the Service does not violate, misappropriate or infringe on the rights of any third party, including, without limitation, privacy rights, publicity rights, copyrights, trademark and/or other intellectual property rights; (iii) You agree to pay for all royalties, fees, and any other monies owed through use of Your Content on the Service; and (iv) You have the legal right and capacity to enter into this Agreement.
                                            </p>
                                            <p>If You are unsure about your rights regarding the usage of Your Content, You agree to perform all due diligence on Your part to ensure that Your Content is free for You to use on the Service.</p>

                                        </section>


                                    </div>
                                    <div className="page-footer panel">
                                        <p className="fs-7 opacity-60 m-0">Last updated: 1st October 2024</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IntellectualPolicy