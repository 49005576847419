import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Modal, Nav, Navbar, Offcanvas, Row, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { SERVER_URL, frontEnd_API, header } from '../Config/config';
import FormComponent from '../Components/FormComponent';
import { pattern } from '../Config/Constant';
import { storeUser } from '../Store/Slices/userSlice';
import { updateModal } from '../Store/Slices/modalSlice';
import axios from 'axios';
import { toast } from 'react-toastify';
import { MdDone, MdDoneAll } from 'react-icons/md';
import Swal from 'sweetalert2';

function Header() {

    const user = useSelector((state) => state.user.value);
    const storeData = useSelector((state) => state.store.value);
    const cartData = useSelector((state) => state.cart.value);
    const modalData = useSelector((state) => state.modal.value);
    const dispatch = useDispatch();
    const [signInModal, setSignInModal] = useState(modalData);
    const [signUpModal, setSignUpModal] = useState(false);
    const [forgotModal, setForgotModal] = useState(false);
    const [categoryList, setCategoryList] = useState();

    const [formData, setFormData] = useState({});
    const [error, setError] = useState({});
    const [showEye, setShowEye] = useState(false);
    const [otpBox, setOtpBox] = useState(false);
    const [validOTP, setValidOTP] = useState(false);
    const [waitingTime, setWaitingTime] = useState(true);
    const [timeSeconds, setTimeSeconds] = useState();

    useEffect(() => {
        setSignInModal(modalData)
    }, [modalData])

    const handleModalStatus = (status) => {
        dispatch(updateModal(status))
        setSignInModal(status)
        console.log(status);
    }

    const handleChange = (item) => {

        delete error[item?.key];

        // For Chacking Required value
        if (item?.pattern || item?.required) {
            if (item?.required && item?.value?.length <= 0) {
                error[item?.key] = `${item?.name} is required`
            }

            if (item?.pattern && item?.value?.length > 0) {
                const patternCheck = new RegExp(item?.pattern);
                if (!patternCheck.test(item?.value))
                    error[item?.key] = `Invalid pattern for ${item?.name}`;
            }
        }
        console.log("item::", item);

        // Store Data in formData
        const { key, value } = item
        setFormData((prevData) => ({
            ...prevData,
            [key]: value
        }))

        setError({ ...error });

        if (item?.name == "mobile") {
            if (item?.pattern && item?.value?.length > 0) {
                const patternCheck = new RegExp(item?.pattern);
                if (!patternCheck.test(item?.value)) {
                    setOtpBox(false);
                    setValidOTP(false);
                }
            }
        }

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(frontEnd_API.signup, formData, header)
            .then((res) => {
                console.log(res?.data);
                dispatch(storeUser(res?.data?.data));
                toast('User Created Successfully !')
                setFormData({});
                window.location.reload();
                dispatch(updateModal(false))
                setSignInModal(false)
            })
            .catch((err) => {
                console.log("err::", err);
                if (err?.response?.data?.data?.non_field_message) {
                    toast(err?.response?.data?.data?.non_field_message)
                }
                else {
                    setError(err?.response?.data?.data);
                }
            })
    }

    const handleForgotSubmit = (e) => {
        e.preventDefault();
        axios.post(frontEnd_API.updatepassword, {
            mobile: formData?.mobile,
            newPassword: formData?.newPassword,
            confirmPassword: formData?.confirmPassword
        }, header)
            .then((res) => {
                console.log(res?.data);
                toast('Password changed successfully!')
                setTimeout(() => {
                    setFormData({});
                    setForgotModal(false);
                    handleModalStatus(true);
                }, 2000)
            })
            .catch((err) => {
                console.log("err::", err);
                if (err?.response?.data?.data?.non_field_message) {
                    toast(err?.response?.data?.data?.non_field_message)
                }
                else {
                    setError(err?.response?.data?.data);
                }
            })
    }

    const toggleEye = (field) => {
        setShowEye((prev) => ({
            ...prev,
            [field]: !prev[field]
        }));
    }

    const generateOTP = async () => {
        if (formData?.mobile) {
            try {
                let response = {};
                if (!otpBox) {
                    console.log("First");
                    response = await axios.put(frontEnd_API?.sendotp, { mobile: formData?.mobile }, header)
                }
                else {
                    console.log("Retry");
                    response = await axios.put(frontEnd_API?.resendotp, { mobile: formData?.mobile }, header)
                }
                console.log("data?.data::", response);
                setOtpBox(true);
                setWaitingTime(false);

                let countdown = 45;
                setTimeSeconds(countdown);
                const timer = setInterval(() => {
                    setTimeSeconds(countdown);
                    countdown--;

                    if (countdown === 0) {
                        clearInterval(timer);
                        setWaitingTime(true);
                    }
                }, 1000);
            }
            catch (err) {
                console.log("Err::", err);
                if (err?.response?.data?.data?.mobile) {
                    setError((prevData) => ({
                        ...prevData,
                        ...err?.response?.data?.data
                    }));
                    setOtpBox(false);
                }
            }
        }
    }

    const generateForgotOTP = async () => {
        if (formData?.mobile) {
            try {
                let response = {};
                if (!otpBox) {
                    console.log("First");
                    response = await axios.put(frontEnd_API?.sendotp, { mobile: formData?.mobile, isForget: 1 }, header)
                }
                else {
                    console.log("Retry");
                    response = await axios.put(frontEnd_API?.resendotp, { mobile: formData?.mobile, isForget: 1 }, header)
                }
                console.log("data?.data::", response);
                setOtpBox(true);
                setWaitingTime(false);

                let countdown = 45;
                setTimeSeconds(countdown);
                const timer = setInterval(() => {
                    setTimeSeconds(countdown);
                    countdown--;

                    if (countdown === 0) {
                        clearInterval(timer);
                        setWaitingTime(true);
                    }
                }, 1000);
            }
            catch (err) {
                console.log("Err::", err);
                if (err?.response?.data?.data?.mobile) {
                    setError((prevData) => ({
                        ...prevData,
                        ...err?.response?.data?.data
                    }));
                    setOtpBox(false);
                }
            }
        }
    }

    const validateOTP = async () => {
        try {
            const response = await axios.put(frontEnd_API?.verifyotp, { mobile: formData?.mobile, otp: formData?.otp }, header)
            console.log("response::", response);
            if (response?.status == 200)
                setValidOTP(true);
        }
        catch (err) {
            console.log("Err::", err);
            if (err?.response?.data?.data?.otp) {
                setError((prevData) => ({
                    ...prevData,
                    ...err?.response?.data?.data
                }));
                setValidOTP(false);
            }
        }
    }

    const getCategoryList = async () => {
        try {
            console.log("header::", header);
            const response = await axios.get(frontEnd_API?.getCategory, header)
            if (response?.status == 200) {
                console.log("response?.data::", response?.data);
                setCategoryList(response?.data?.data)
            }
        }
        catch (e) {
            console.log("Err:", e);
        }
    }

    useEffect(() => {
        getCategoryList();
    }, [])

    const handleRedirect = (item) => {
        // const { url, data } = redirectValue;
        if (item) {
            const kebabCaseStr = item.toLowerCase().replace(/-/g, '~').replace(/\s+/g, '-');
            return kebabCaseStr;
        }
    };

    return (
        <div className='custom-header-style'>
            <Navbar expand={'lg'} className="custom-nav-bg py-1">
                <Container>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} className='p-0 custom-canvas' style={{ border: '0px' }} >
                        <i className='bi bi-list custom-menu-icon'></i>
                    </Navbar.Toggle>
                    <Navbar.Brand>
                        <div className='custom-img-holder-container d-flex align-items-center gap-2'>
                            <Link to={'/'} className='custom-img-holder'>
                                <img src={(storeData?.logo) ? storeData?.logo : require('../Asstes/Images/try-logo.png')} alt="" />
                            </Link>
                            <Link to={'/'} className='h3 text-decoration-none m-0 fw-bold text-capitalize d-none d-md-block me-3'>{storeData?.name}</Link>
                            <Link to={'/'} className='h3 text-decoration-none m-0 fw-bold text-capitalize d-block d-md-none'>{storeData?.name.substring(0, 12)}{(storeData?.name.length > 12) && '...'}</Link>
                        </div>
                    </Navbar.Brand>
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-lg`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                        placement="start"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                                <span className='text-capitalize'>
                                    {storeData?.name}
                                </span>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body className='d-grid d-md-flex gap-2 align-content-start'>
                            <Nav className="justify-content-center flex-grow-1 pe-3 align-items-md-center gap-3 custom-navs-list">
                                <Link to={'/'} className='custom-navs-list-link'>All Products</Link>
                                {
                                    (categoryList?.length > 0) &&
                                    categoryList.map((item, index) => (
                                        <Link
                                            key={index}
                                            to={`/category/${handleRedirect(item?.categoryName)}`}
                                            className='custom-navs-list-link text-capitalize'
                                        >
                                            {item?.categoryName}
                                        </Link>
                                    ))
                                }
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                    <div className='flex-center-align px-2 custom-cart-container gap-sm-4 gap-3'>
                        <Link to={(user) && '/user'} className='bi bi-person-circle fs-25 fs-sm-18 custom-account'
                            onClick={() => {
                                (!user) && handleModalStatus(true)
                            }}
                        />
                        <Link to={'/cart'} className="fs-sm-18 bi bi-cart3 fs-25 grid-center-align">
                            <div className='d-none d-sm-flex'>
                                <span>{(cartData?.length) ?? '0'}</span>
                            </div>
                        </Link>
                    </div>
                </Container>
            </Navbar>


            <Modal className={`custom-signin-modal ${storeData?.currentTheme}`} size="md" show={signInModal} onHide={() => handleModalStatus(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className='custom-signin-modal-body p-0'>
                    <Link className='bi bi-x-lg custom-close-btn fs-20' onClick={() => handleModalStatus(false)}></Link>
                    <div className='d-grid custom-heading gap-3'>
                        <div className='d-grid gap-2 p-3 pb-0'>
                            <h4 className='text-center m-0'>Sign In</h4>
                            <p className='fs-14 fs-sm-12 text-center'>Sign in so you can save items to your wishlists, track your orders , and checkout faster!</p>
                        </div>
                        <div className='p-3 py-0 py-sm-2'>
                            <FormComponent
                                action={frontEnd_API.signin}
                                dispatchRedux={storeUser}
                                submitBTNAlign={'justify-content-center'}
                                loadPage={true}
                                button={'Sign in'}
                                isForgetPassword={true}
                                handleForgateClick={() => {
                                    setForgotModal(true)
                                    handleModalStatus(false)
                                }}
                                formMessage={"Login Successfully !"}
                                fields={[
                                    {
                                        divClass: 'col-12',
                                        name: 'email',
                                        key: 'email',
                                        required: true,
                                        pattern: pattern.email,
                                    },
                                    {
                                        divClass: 'col-12',
                                        name: 'Password',
                                        key: 'password',
                                        type: 'password',
                                        required: true,
                                        pattern: pattern.password,
                                    }
                                ]}
                            />
                        </div>
                        <hr className='m-0' />
                        <div className='d-grid d-sm-flex flex-center-align gap-1 p-3 pt-0'>
                            Don't have an account?
                            <Link className='text-center' onClick={() => {
                                setSignUpModal(true);
                                handleModalStatus(false)
                            }}> Sign Up</Link>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className={`custom-signin-modal ${storeData?.currentTheme}`} size="md" show={signUpModal} onHide={() => setSignUpModal(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className='custom-signin-modal-body p-0'>
                    <Link className='bi bi-x-lg custom-close-btn fs-20' onClick={() => setSignUpModal(false)}></Link>
                    <div className='d-grid custom-heading gap-3'>
                        <div className='d-grid gap-2 p-3 pb-0'>
                            <h4 className='text-center m-0'>Sign Up</h4>
                            <p className='fs-14 fs-sm-12 text-center'>Welcome to <span>{storeData?.name}</span>! It's quick and easy to set up an account</p>
                        </div>
                        <div className='p-3 py-0 py-sm-2'>
                            <Form onSubmit={(e) => handleSubmit(e)}>
                                <Row className='g-2'>

                                    <Col className={`${new RegExp(pattern?.mobile).test(formData?.mobile) ? "col-12 col-md-8" : "col-12"}`}>
                                        <label htmlFor={"mobile"} className='fs-sm-14 text-capitalize'>Mobile<span className={'text-danger'}>*</span> </label>
                                        <span className={`d-block custom-password-input custom-remove-number`} >
                                            <input
                                                type={'number'}
                                                id={"mobile"}
                                                placeholder={`Enter 10 digit mobile number`}
                                                className={`w-100 p-2 fs-sm-14 seller-box ${(error?.mobile) && 'border-danger'}`}
                                                name={"mobile"}
                                                value={formData?.mobile ? formData?.mobile : ''}

                                                onChange={(e) => handleChange(
                                                    {
                                                        name: e.target?.name,
                                                        key: e.target?.id,
                                                        pattern: pattern?.mobile,
                                                        value: e.target?.value,
                                                        required: true
                                                    }
                                                )}
                                                onBlur={(e) => handleChange(
                                                    {
                                                        name: e.target?.name,
                                                        key: e.target?.id,
                                                        pattern: pattern?.mobile,
                                                        value: e.target?.value,
                                                        required: true
                                                    }
                                                )}
                                                autoComplete='off'
                                                required={true}
                                                disabled={otpBox}
                                            />
                                            {(formData?.mobile) &&
                                                <span className={`custom-eye`}>
                                                    <i
                                                        className={`bi fs-18 ${(otpBox) ? "bi-pen-fill text-dark" : (new RegExp(pattern?.mobile).test(formData?.mobile) && !error?.mobile) ? "bi-check-lg text-success" : 'bi-x-lg text-danger'}`}
                                                        onClick={() => {
                                                            if (otpBox) {
                                                                Swal.fire({
                                                                    title: `Confirm mobile number edit`,
                                                                    text: `This action cannot be undone. Once you click "Yes," you will need to re-enter all the required information.`,
                                                                    icon: 'warning',
                                                                    showCancelButton: true,
                                                                    confirmButtonColor: '#12715B',
                                                                    cancelButtonColor: '#d33',
                                                                    confirmButtonText: 'Yes',
                                                                    cancelButtonText: 'No',
                                                                }).then(async (result) => {
                                                                    if (result.isConfirmed) {
                                                                        setOtpBox(false)
                                                                        setValidOTP(false)
                                                                    }
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </span>
                                            }
                                        </span>
                                        <span className='text-danger fs-12 mb-2 custom-error-height fw-semibold'>{error?.mobile}</span>
                                    </Col>
                                    {(new RegExp(pattern?.mobile).test(formData?.mobile)) &&
                                        <Col className={`col-12 col-md-4 d-grid ${error?.mobile ? "align-content-center" : "align-content-end"}`}>
                                            <Button
                                                onClick={() => generateOTP()}
                                                disabled={validOTP || !waitingTime}
                                                style={{ minHeight: "42px" }}>
                                                {
                                                    (validOTP) ? <span className='fs-18 flex-center-align'><MdDone /></span>
                                                        : (waitingTime)
                                                            ? (otpBox) ? "Resend OTP" : "Send OTP"
                                                            : <span className='flex-center-align gap-2 fs-12 fw-semibold'>
                                                                <Spinner animation="grow" size="sm" />
                                                                Wait for {timeSeconds}s
                                                            </span>
                                                }
                                            </Button>
                                        </Col>
                                    }
                                    {(new RegExp(pattern?.mobile).test(formData?.mobile) && otpBox && !validOTP) &&
                                        <Col className={`col-12 fs-12 ${(error?.mobile || error?.otp) ? "d-none" : ""}`}>
                                            *The OTP is valid for 3 minutes.
                                        </Col>
                                    }
                                    {(new RegExp(pattern?.mobile).test(formData?.mobile) && otpBox) &&
                                        <>
                                            <Col className={`col-8`}>
                                                <label htmlFor={"otp"} className='fs-sm-14 text-capitalize'>OTP<span className={'text-danger'}>*</span> </label>
                                                <span className={`d-block custom-password-input custom-remove-number`} >
                                                    <input
                                                        type={'number'}
                                                        id={"otp"}
                                                        placeholder={`Enter OTP here ...`}
                                                        className={`w-100 p-2 fs-sm-14 seller-box ${(error?.otp) && 'border-danger'}`}
                                                        name={"otp"}
                                                        autoComplete='off'
                                                        required={true}
                                                        onChange={(e) => handleChange(
                                                            {
                                                                name: e.target?.name,
                                                                key: e.target?.id,
                                                                value: e.target?.value,
                                                                required: true
                                                            }
                                                        )}
                                                        onBlur={(e) => handleChange(
                                                            {
                                                                name: e.target?.name,
                                                                key: e.target?.id,
                                                                value: e.target?.value,
                                                                required: true
                                                            }
                                                        )}
                                                        disabled={validOTP}
                                                    />
                                                </span>
                                                <span className='text-danger fs-12 mb-2 custom-error-height fw-semibold'>{error?.otp}</span>
                                            </Col>
                                            <Col className={`col-4 d-grid ${error?.otp ? "align-content-center" : "align-content-end"}`}>
                                                <Button
                                                    variant='success'
                                                    onClick={() => validateOTP()}
                                                    style={{ minHeight: "42px" }}
                                                    disabled={validOTP ?? error?.otp ? true : formData?.otp?.length > 0 ? false : true}
                                                >
                                                    {
                                                        (!validOTP) ?
                                                            "Verify"
                                                            : <span className='fs-18 flex-center-align'><MdDoneAll /></span>
                                                    }
                                                </Button>
                                            </Col>
                                        </>
                                    }
                                    {
                                        (new RegExp(pattern?.mobile).test(formData?.mobile) && otpBox && validOTP) &&
                                        <>
                                            <Col className="col-12">
                                                <label htmlFor={"name"} className='fs-sm-14 text-capitalize'>Name<span className={'text-danger'}>*</span> </label>
                                                <span className={`d-block custom-password-input`} >
                                                    <input
                                                        type={'text'}
                                                        id={"name"}
                                                        placeholder={`enter your name`}
                                                        className={`w-100 p-2 fs-sm-14 seller-box ${(error?.name) && 'border-danger'}`}
                                                        name={"name"}
                                                        value={formData?.name ? formData?.name : ''}

                                                        onChange={(e) => handleChange(
                                                            {
                                                                name: e.target?.name,
                                                                key: e.target?.id,
                                                                value: e.target?.value,
                                                                required: true
                                                            }
                                                        )}
                                                        onBlur={(e) => handleChange(
                                                            {
                                                                name: e.target?.name,
                                                                key: e.target?.id,
                                                                value: e.target?.value,
                                                                required: true
                                                            }
                                                        )}
                                                        autoComplete='off'
                                                        required={true}
                                                    />
                                                </span>
                                                <span className='text-danger fs-12 mb-2 custom-error-height fw-semibold'>{error?.name}</span>
                                            </Col>
                                            <Col className="col-12 col-md-6">
                                                <label htmlFor={"email"} className='fs-sm-14 text-capitalize'>Email<span className={'text-danger'}>*</span> </label>
                                                <span className={`d-block custom-password-input`} >
                                                    <input
                                                        type={'email'}
                                                        id={"email"}
                                                        placeholder={`sample@gmail.com`}
                                                        className={`w-100 p-2 fs-sm-14 seller-box ${(error?.email) && 'border-danger'}`}
                                                        name={"email"}
                                                        value={formData?.email ? formData?.email : ''}

                                                        onChange={(e) => handleChange(
                                                            {
                                                                name: e.target?.name,
                                                                pattern: pattern?.email,
                                                                key: e.target?.id,
                                                                value: e.target?.value,
                                                                required: true
                                                            }
                                                        )}
                                                        onBlur={(e) => handleChange(
                                                            {
                                                                name: e.target?.name,
                                                                pattern: pattern?.email,
                                                                key: e.target?.id,
                                                                value: e.target?.value,
                                                                required: true
                                                            }
                                                        )}
                                                        autoComplete='off'
                                                        required={true}
                                                    />
                                                </span>
                                                <span className='text-danger fs-12 mb-2 custom-error-height fw-semibold'>{error?.email}</span>
                                            </Col>
                                            <Col className="col-12 col-md-6">
                                                <label htmlFor={"password"} className='fs-sm-14 text-capitalize'>password<span className={'text-danger'}>*</span> </label>
                                                <span className={`d-block custom-password-input`} >
                                                    <input
                                                        type={(showEye?.password) ? 'text' : 'password'}
                                                        id={"password"}
                                                        placeholder={`Enter Strong password`}
                                                        className={`w-100 p-2 fs-sm-14 seller-box ${(error?.password) && 'border-danger'}`}
                                                        name={"password"}
                                                        value={formData?.password ? formData?.password : ''}

                                                        onChange={(e) => handleChange(
                                                            {
                                                                name: e.target?.name,
                                                                pattern: pattern?.password,
                                                                key: e.target?.id,
                                                                value: e.target?.value,
                                                                required: true
                                                            }
                                                        )}
                                                        onBlur={(e) => handleChange(
                                                            {
                                                                name: e.target?.name,
                                                                pattern: pattern?.password,
                                                                key: e.target?.id,
                                                                value: e.target?.value,
                                                                required: true
                                                            }
                                                        )}
                                                        autoComplete='off'
                                                        required={true}
                                                    />
                                                    <span className={`custom-eye`}>
                                                        <i className={`bi fs-18 ${(!showEye.password) ? 'bi-eye-slash' : "bi-eye"}`} onClick={() => toggleEye("password")}></i>
                                                    </span>
                                                </span>
                                                <span className='text-danger fs-12 mb-2 custom-error-height fw-semibold'>{error?.password}</span>
                                            </Col>
                                            <Col lg='12' md='12' className="mt-3">
                                                <div className={`text-start d-flex justify-content-center`}>
                                                    <Button color="info" className={`custom-submit-btn btn fw-semibold info-btn text-capitalize`} type="submit" disabled={Object?.keys(error).length !== 0}>
                                                        Sign up
                                                    </Button>
                                                </div>
                                            </Col>
                                        </>
                                    }
                                </Row>
                            </Form>
                        </div>
                        <hr className='m-0' />
                        <div className='d-grid d-sm-flex flex-center-align gap-1 p-3 pt-0'>
                            Already have an account?
                            <Link className='text-center' onClick={() => {
                                handleModalStatus(true);
                                setSignUpModal(false)
                            }}> Sign In</Link>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className={`custom-signin-modal ${storeData?.currentTheme}`} size="md" show={forgotModal} backdrop="static" onHide={() => setForgotModal(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className='custom-signin-modal-body p-0'>
                    <Link className='bi bi-x-lg custom-close-btn fs-20' onClick={() => setForgotModal(false)}></Link>
                    <div className='d-grid custom-heading gap-3'>
                        <div className='d-grid gap-2 p-3 pb-0'>
                            <h4 className='text-center m-0'>Forgot password</h4>
                            {/* <p className='fs-14 fs-sm-12 text-center'>Welcome to <span>{storeData?.name}</span>! It's quick and easy to set up an account</p> */}
                        </div>
                        <div className='p-3 py-0 py-sm-2'>
                            <Form onSubmit={(e) => handleForgotSubmit(e)}>
                                <Row className='g-2'>

                                    <Col className={`${new RegExp(pattern?.mobile).test(formData?.mobile) ? "col-12 col-md-8" : "col-12"}`}>
                                        <label htmlFor={"mobile"} className='fs-sm-14 text-capitalize'>Mobile<span className={'text-danger'}>*</span> </label>
                                        <span className={`d-block custom-password-input custom-remove-number`} >
                                            <input
                                                type={'number'}
                                                id={"mobile"}
                                                placeholder={`Enter 10 digit mobile number`}
                                                className={`w-100 p-2 fs-sm-14 seller-box ${(error?.mobile) && 'border-danger'}`}
                                                name={"mobile"}
                                                value={formData?.mobile ? formData?.mobile : ''}

                                                onChange={(e) => handleChange(
                                                    {
                                                        name: e.target?.name,
                                                        key: e.target?.id,
                                                        pattern: pattern?.mobile,
                                                        value: e.target?.value,
                                                        required: true
                                                    }
                                                )}
                                                onBlur={(e) => handleChange(
                                                    {
                                                        name: e.target?.name,
                                                        key: e.target?.id,
                                                        pattern: pattern?.mobile,
                                                        value: e.target?.value,
                                                        required: true
                                                    }
                                                )}
                                                autoComplete='off'
                                                required={true}
                                                disabled={otpBox}
                                            />
                                            {/* {(formData?.mobile) &&
                                                <span className={`custom-eye`}>
                                                    <i
                                                        className={`bi fs-18 ${(otpBox) ? "bi-pen-fill text-dark" : (new RegExp(pattern?.mobile).test(formData?.mobile) && !error?.mobile) ? "bi-check-lg text-success" : 'bi-x-lg text-danger'}`}
                                                        onClick={() => {
                                                            if (otpBox) {
                                                                Swal.fire({
                                                                    title: `Confirm mobile number edit`,
                                                                    text: `This action cannot be undone. Once you click "Yes," you will need to re-enter all the required information.`,
                                                                    icon: 'warning',
                                                                    showCancelButton: true,
                                                                    confirmButtonColor: '#12715B',
                                                                    cancelButtonColor: '#d33',
                                                                    confirmButtonText: 'Yes',
                                                                    cancelButtonText: 'No',
                                                                }).then(async (result) => {
                                                                    if (result.isConfirmed) {
                                                                        setOtpBox(false)
                                                                        setValidOTP(false)
                                                                    }
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </span>
                                            } */}
                                        </span>
                                        <span className='text-danger fs-12 mb-2 custom-error-height fw-semibold'>{error?.mobile}</span>
                                    </Col>
                                    {(new RegExp(pattern?.mobile).test(formData?.mobile)) &&
                                        <Col className={`col-12 col-md-4 d-grid ${error?.mobile ? "align-content-center" : "align-content-end"}`}>
                                            <Button
                                                onClick={() => generateForgotOTP()}
                                                disabled={validOTP || !waitingTime}
                                                style={{ minHeight: "42px" }}>
                                                {
                                                    (validOTP) ? <span className='fs-18 flex-center-align'><MdDone /></span>
                                                        : (waitingTime)
                                                            ? (otpBox) ? "Resend OTP" : "Send OTP"
                                                            : <span className='flex-center-align gap-2 fs-12 fw-semibold'>
                                                                <Spinner animation="grow" size="sm" />
                                                                Wait for {timeSeconds}s
                                                            </span>
                                                }
                                            </Button>
                                        </Col>
                                    }
                                    {(new RegExp(pattern?.mobile).test(formData?.mobile) && otpBox && !validOTP) &&
                                        <Col className={`col-12 fs-12 ${(error?.mobile || error?.otp) ? "d-none" : ""}`}>
                                            *The OTP is valid for 3 minutes.
                                        </Col>
                                    }
                                    {(new RegExp(pattern?.mobile).test(formData?.mobile) && otpBox) &&
                                        <>
                                            <Col className={`col-8`}>
                                                <label htmlFor={"otp"} className='fs-sm-14 text-capitalize'>OTP<span className={'text-danger'}>*</span> </label>
                                                <span className={`d-block custom-password-input custom-remove-number`} >
                                                    <input
                                                        type={'number'}
                                                        id={"otp"}
                                                        placeholder={`Enter OTP here ...`}
                                                        className={`w-100 p-2 fs-sm-14 seller-box ${(error?.otp) && 'border-danger'}`}
                                                        name={"otp"}
                                                        autoComplete='off'
                                                        required={true}
                                                        onChange={(e) => handleChange(
                                                            {
                                                                name: e.target?.name,
                                                                key: e.target?.id,
                                                                value: e.target?.value,
                                                                required: true
                                                            }
                                                        )}
                                                        onBlur={(e) => handleChange(
                                                            {
                                                                name: e.target?.name,
                                                                key: e.target?.id,
                                                                value: e.target?.value,
                                                                required: true
                                                            }
                                                        )}
                                                        disabled={validOTP}
                                                    />
                                                </span>
                                                <span className='text-danger fs-12 mb-2 custom-error-height fw-semibold'>{error?.otp}</span>
                                            </Col>
                                            <Col className={`col-4 d-grid ${error?.otp ? "align-content-center" : "align-content-end"}`}>
                                                <Button
                                                    variant='success'
                                                    onClick={() => validateOTP()}
                                                    style={{ minHeight: "42px" }}
                                                    disabled={validOTP ?? error?.otp ? true : formData?.otp?.length > 0 ? false : true}
                                                >
                                                    {
                                                        (!validOTP) ?
                                                            "Verify"
                                                            : <span className='fs-18 flex-center-align'><MdDoneAll /></span>
                                                    }
                                                </Button>
                                            </Col>
                                        </>
                                    }
                                    {
                                        (new RegExp(pattern?.mobile).test(formData?.mobile) && otpBox && validOTP) &&
                                        <>
                                            <Col className="col-12">
                                                <label htmlFor={"newPassword"} className='fs-sm-14 text-capitalize'>New Password<span className={'text-danger'}>*</span> </label>
                                                <span className={`d-block custom-password-input`} >
                                                    <input
                                                        type={(showEye?.newPassword) ? 'text' : 'password'}
                                                        id={"newPassword"}
                                                        placeholder={`Enter Strong password`}
                                                        className={`w-100 p-2 fs-sm-14 seller-box ${(error?.newPassword) && 'border-danger'}`}
                                                        name={"newPassword"}
                                                        value={formData?.newPassword ?? ''}

                                                        onChange={(e) => handleChange(
                                                            {
                                                                name: e.target?.name,
                                                                pattern: pattern?.password,
                                                                key: e.target?.id,
                                                                value: e.target?.value,
                                                                required: true
                                                            }
                                                        )}
                                                        onBlur={(e) => handleChange(
                                                            {
                                                                name: e.target?.name,
                                                                pattern: pattern?.password,
                                                                key: e.target?.id,
                                                                value: e.target?.value,
                                                                required: true
                                                            }
                                                        )}
                                                        autoComplete='off'
                                                        required={true}
                                                    />
                                                    <span className={`custom-eye`}>
                                                        <i className={`bi fs-18 ${(!showEye.password) ? 'bi-eye-slash' : "bi-eye"}`} onClick={() => toggleEye("newPassword")}></i>
                                                    </span>
                                                </span>
                                                <span className='text-danger fs-12 mb-2 custom-error-height fw-semibold'>{error?.newPassword}</span>
                                            </Col>
                                            <Col className="col-12">
                                                <label htmlFor={"confirmPassword"} className='fs-sm-14 text-capitalize'>Confirm Password<span className={'text-danger'}>*</span> </label>
                                                <span className={`d-block custom-password-input`} >
                                                    <input
                                                        type={(showEye?.confirmPassword) ? 'text' : 'password'}
                                                        id={"confirmPassword"}
                                                        placeholder={`Enter Strong password`}
                                                        className={`w-100 p-2 fs-sm-14 seller-box ${(error?.confirmPassword) && 'border-danger'}`}
                                                        name={"confirmPassword"}
                                                        value={formData?.confirmPassword ?? ''}

                                                        onChange={(e) => handleChange(
                                                            {
                                                                name: e.target?.name,
                                                                pattern: pattern?.password,
                                                                key: e.target?.id,
                                                                value: e.target?.value,
                                                                required: true
                                                            }
                                                        )}
                                                        onBlur={(e) => handleChange(
                                                            {
                                                                name: e.target?.name,
                                                                pattern: pattern?.password,
                                                                key: e.target?.id,
                                                                value: e.target?.value,
                                                                required: true
                                                            }
                                                        )}
                                                        autoComplete='off'
                                                        required={true}
                                                    />
                                                    <span className={`custom-eye`}>
                                                        <i className={`bi fs-18 ${(!showEye.password) ? 'bi-eye-slash' : "bi-eye"}`} onClick={() => toggleEye("confirmPassword")}></i>
                                                    </span>
                                                </span>
                                                <span className='text-danger fs-12 mb-2 custom-error-height fw-semibold'>{error?.confirmPassword}</span>
                                            </Col>
                                            <Col lg='12' md='12' className="mt-3">
                                                <div className={`text-start d-flex justify-content-center`}>
                                                    <Button color="info" className={`custom-submit-btn btn fw-semibold info-btn text-capitalize`} type="submit" disabled={Object?.keys(error).length !== 0}>
                                                        Sign up
                                                    </Button>
                                                </div>
                                            </Col>
                                        </>
                                    }
                                </Row>
                            </Form>
                        </div>
                        <hr className='m-0' />
                        <div className='d-grid d-sm-flex flex-center-align gap-1 p-3 pt-0'>
                            Already have an account?
                            <Link className='text-center' onClick={() => {
                                handleModalStatus(true);
                                setForgotModal(false)
                            }}> Sign In</Link>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default Header
