import { createSlice } from '@reduxjs/toolkit';
import { storage } from '../../Config/config';

const initialState = {
    value: JSON.parse(localStorage.getItem(storage.cart)) || [],
};

// Utility function to compare two variantData objects
const areVariantsEqual = (variantData1, variantData2) => {
    return (
        Object.keys(variantData1).length === Object.keys(variantData2).length &&
        Object.keys(variantData1).every(
            key => variantData1[key] === variantData2[key]
        )
    );
};

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addCart: (state, action) => {
            state.value = action.payload;
            localStorage.setItem(storage.cart, JSON.stringify(state.value));
        },
        updateCart: (state, action) => {
            if (state?.value?.length > 0) {
                const existingItemIndex = state.value.findIndex(
                    item => item.productId === action.payload.productId
                );

                if (existingItemIndex !== -1) {
                    const existingItem = state.value[existingItemIndex];

                    // Check if the variantData objects are deeply equal
                    const isVariantSame = areVariantsEqual(
                        existingItem.variantData,
                        action.payload.variantData
                    );

                    if (isVariantSame) {
                        // If variantData is the same, update the quantity
                        existingItem.quantity = action.payload.quantity;
                    } else {
                        // If variantData is different, add the new item as a new object
                        state.value.push(action.payload);
                    }
                } else {
                    // Add the new item if productId doesn't exist
                    state.value.push(action.payload);
                }
            } else {
                state.value = [action.payload];
            }
            localStorage.setItem(storage.cart, JSON.stringify(state.value));
        },
        deleteCart: (state, action) => {
            if (state?.value?.length > 1) {
                state.value.splice(action.payload, 1);
            } else {
                state.value = [];
            }
            localStorage.setItem(storage.cart, JSON.stringify(state.value));
        },
        removeCart: (state) => {
            state.value = [];
            localStorage.removeItem(storage.cart);
            localStorage.removeItem(storage.couponCode);
        },
    },
});

// Action creators are generated for each case reducer function
export const { addCart, updateCart, deleteCart, removeCart } = cartSlice.actions;

export default cartSlice.reducer;
