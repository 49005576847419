import { configureStore } from '@reduxjs/toolkit'
import storeSlice from './Slices/storeSlice'
import userSlice from './Slices/userSlice'
import cartSlice from './Slices/cartSlice'
import modalSlice from './Slices/modalSlice'
import homeLoader from './Slices/homeLoader'

export const store = configureStore({
    reducer: {
        store: storeSlice,
        user: userSlice,
        cart: cartSlice,
        modal: modalSlice,
        loader: homeLoader,
    },
})