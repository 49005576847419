import React from 'react'

function TermsOfServices() {
    return (
        <div>
            <div className='container cs-container-xl' style={{ paddingTop: "3rem",paddingBottom: "3rem" }}>
                <div className='row child-cols'>
                    <div className='col-md-4 d-none d-md-grid sidebar-list'>
                        <div className=''>
                            <ol className='position-sticky my-pages-content fs-14 start-0 d-grid gap-3' style={{ top: "65px", listStyleType: "upper-alpha" }}>
                                <li className='pointer' >
                                    <a href="#p-a" className='theme-color text-decoration-none'>Definitions</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-b" className='text-decoration-none'>General</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-c" className='text-decoration-none'>When Do These Terms Apply?</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-d" className='text-decoration-none'>Can These Terms Be Modified?</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-e" className='text-decoration-none'>What Is the Scope of Printfuse’s Services?</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-f" className='text-decoration-none'>Who Can Access Printfuse and Use the Service?</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-g" className='text-decoration-none'>What Are the Rules for Creating and Maintaining an Account?</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-h" className='text-decoration-none'>What Are Your Rights to Content on Printfuse?</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-i" className='text-decoration-none'>How do Payments Work on Printfuse?</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-j" className='text-decoration-none'>How Does Printfuse Handle Order Related Issues?</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-k" className='text-decoration-none'>Embroidery</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-l" className='text-decoration-none'>Can You Use Printfuse’s Image and Name?</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-m" className='text-decoration-none'>Limitation of Liability</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-n" className='text-decoration-none'>What if I Have Legal Issues with Printfuse?</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-o" className='text-decoration-none'>Printfuse Plan</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-p" className='text-decoration-none'>Printfuse FX Rate</a>
                                </li>
                                <li className='pointer'>
                                    <a href="#p-q" className='text-decoration-none'>Printfuse SnapStore</a>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className='col-12 col-md-8 d-grid align-content-start'>
                        <div className="section">
                            <div className="page-wrap panel vstack gap-4 lg:gap-6 xl:gap-8">
                                <section className='grid gap-3 gap-sm-4'>
                                    <h1 className='m-0'>Terms of Services</h1>
                                    <address className='m-0 p-text'>
                                        <strong className='fw-semibold'>PRINTFUSE GLOBAL PRIVATE LIMITED</strong> <br />
                                        Ground Floor Plot No 21 <br />
                                        Sec-142 Noida <br />
                                        Uttar Pradesh India 201305
                                    </address>
                                    <span className='p-text'>Last updated: 1st October 2024</span>
                                </section>
                                <div className="page-content panel fs-6 md:fs-5">
                                    <p>
                                        Thank you for using Printfuse! Before using or accessing our platform, please read these Terms of Service (“Terms of Service”) carefully. This document is a legally binding contract between Printfuse and you, the User (as defined below), for the use of the applications, software, products, and services provided by Printfuse (collectively, the “Service”). We’ve provided brief summaries with each clause, but these do not replace the full text.
                                    </p>
                                    <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2" id='p-a'>A. Definitions</h3>
                                    <p>
                                        <strong>Summary:</strong> These terms are used throughout this Terms of Service. It’s important to understand what we are referring to when using them. Terms not defined in this section shall have the meaning provided elsewhere in the Terms of Service.
                                    </p>
                                    <ul>
                                        <li>
                                            <strong>“Agreement”</strong> refers, collectively, to all the terms, conditions, notices contained or referenced in this document (the “Terms of Service,”) and all other operating rules, policies, including <strong>Printfuse</strong> Privacy Policy and <strong>Printfuse</strong> Intellectual Property Policy, and procedures that we may publish from time to time on the Website including but not limited to Shipping, Payments, and Returns.
                                        </li>
                                        <li>
                                            <strong>“Content”</strong> refers to content featured or displayed through the Website, including without limitation text, data, articles, images, photographs, graphics, software, applications, designs, features, and other materials that are available on the Website or otherwise available through the Service.
                                        </li>
                                        <li>
                                            <strong>“Consumer Packaged Goods”</strong> refers to Products that customers use up and replace on a regular or frequent basis offered on our Website including, but not limited to cosmetics, coffee, and supplements.
                                        </li>
                                        <li>
                                            <strong>“Customers”</strong> refers to individuals or entities that purchase Your Products through a Sales Channel.
                                        </li>
                                        <li>
                                            <strong>“Orders”</strong> refer to Your Products once they have been purchased by You or Your customers.
                                        </li>
                                        <li>
                                            <strong>“Produced Orders”</strong> refer to Orders that have been completed and shipped.
                                        </li>
                                        <li>
                                            <strong>“Products”</strong> refers to all products offered by different Providers on our Website including, but not limited to clothing, accessories, home decor, kitchenware, footwear, stationary, cosmetics and other Consumer Packaged Goods.
                                        </li>
                                        <li>
                                            <strong>“Providers”</strong> refers to the organizations and companies, including its third-party contract manufacturers, that Printfuse partners within the scope of its Service to offer Products to You.
                                        </li>
                                        <li>
                                            <strong>“Sales Channels”</strong>, or <strong>“Stores”</strong> refer to the other websites and platforms that You use to sell Your Products, including but not limited to Shopify, WooCommerce, amazon, YouTube, or WhatsApp.
                                        </li>
                                        <li>
                                            <strong>“Us” and/or “We” and/or “Printfuse”</strong> refers to Printfuse, as well as our affiliated legal entities, subsidiaries (including, but not limited to, Printfuse) directors, contractors, licensors, officers, agents, and employees. Printfuse reserves the right to and may provide Services to you through various Printfuse legal entities depending on multiple factors, such as, but not limited to Print Provider’s location, your location, region in which the transaction takes place and others.
                                        </li>
                                        <li>
                                            <strong>“User,” “You,” “Your”</strong> refers to the individual person, company, or organization that has visited or is using the Website or Service; that accesses or uses any part of the account; or that directs the use of the account in the performance of its functions.
                                        </li>
                                        <li>
                                            <strong>“User Products”</strong> refers to Products created by Users adding Content using the Service. <strong>“Your Mock-ups”</strong> are visual representations of Users’ Products created through the Service.
                                        </li>
                                        <li>
                                            <strong>“User-Generated Content”</strong> is Content, written or otherwise that is created or uploaded by our Users.
                                        </li>
                                        <li>
                                            <strong>“Website”</strong> refers to Printfuse’s website located at <strong>Printfuse.in</strong>, and all content, services, and products provided by Printfuse through the Website. It also includes Printfuse-owned subdomains of <strong>Printfuse.in</strong>, such as app.Printfuse.in, Printfuseapp.com, and all subdomains of Printfuseapp.com such as app.Printfuseapp.com, and the use of future and current Printfuse plugins, such as those available in the Shopify App store, Woocommerce, amazon, or YouTube. Occasionally, Printfuse plugin pages and websites owned by Printfuse may provide additional or different terms of service. If those additional terms conflict with these Terms of Service, the terms in the additional terms with regard to the matter in conflict shall apply.
                                        </li>
                                        <li>
                                            <strong>“Your Content”</strong> is Content that you create or own; provided, however, that <strong>“Your Content”</strong> may not, under any circumstances, constitute ingredients or physical additions to any Products which are Consumer Packaged Goods.
                                        </li>
                                        <li>
                                            <strong>“Branding Element”</strong> refers to an element printed on demand and added to the Order, such as a customized card, packaging, or other.
                                        </li>
                                        <li>
                                            <strong>“Illicit Activity”</strong> shall mean any intentional act or omission, including, but not limited to, deception, misrepresentation, or concealment of material facts, committed by a party with the purpose of obtaining an unjust advantage, causing harm or loss to another party, or securing money, property, services, or other benefits, or otherwise contrary to applicable laws and regulations. Illicit Activity may encompass various acts or omissions, such as:
                                            <ul>
                                                <li>(a) defrauding others of money or services, which involves, but is not limited to, obtaining money or services through false pretenses, manipulation, or false promises;</li>
                                                <li>(b) forgery, which includes, but is not limited to, creating, altering, or using false documents, signatures, stolen or forged payment instruments or other items with the intent to deceive or defraud;</li>
                                                <li>(c) fraudulent misrepresentation, which includes, but is not limited to, making false statements or omitting material facts, knowing it to be false or misleading, with the intent to induce another party to rely on such statements or omissions to their detriment;</li>
                                                <li>(d) engaging in, facilitating, or permitting any activity, directly or indirectly, that involves money laundering, proliferation or terrorism financing, circumvention of any sanctions applicable under national or international sanction regimes;</li>
                                                <li>(e) any other acts or practices that are considered unlawful under applicable laws and regulations.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>“Printfuse Snapstore”</strong> refers to Printfuse service, which allows You to publish User Products on a dedicated section of Printfuse e-commerce store that is hosted by Printfuse.
                                        </li>
                                        <li>
                                            <strong>“Printfuse Branding”</strong> refers to Printfuse Service enabling You to send a customized Branding Element to the Customer.
                                        </li>
                                        <li>
                                            <strong>“Your Snapstore”</strong> refers to the section dedicated to you in the e-commerce store hosted by Printfuse that you create by using Printfuse Snapstore Service.
                                        </li>
                                    </ul>

                                    <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2" id='p-b'>B. General</h3>
                                    <ul>
                                        <li>When You use Our Services and Content, You are agreeing to:
                                            <ul>
                                                <li>Our Terms of Service</li>
                                                <li>Our Privacy Policy</li>
                                                <li>Our Intellectual Property Policy</li>
                                                <li>Any other terms or policies we reference below.</li>
                                            </ul>
                                        </li>
                                        <li>Except as they may be supplemented by additional terms and conditions, policies, guidelines or standards, the Agreement constitutes the entire understanding between Printfuse and You pertaining to the subject matter hereof, and supersedes any and all prior oral or written understandings or agreements between Printfuse and You in relation to the access to and use of the Service.</li>
                                        <li>If any provision of these Terms of Service are held to be invalid or unenforceable, such provision will be struck and will not affect the validity and enforceability of the remaining provisions.</li>
                                        <li>Questions about the Terms of Service? <a href="mailto:contact@printfuse.com">Contact us</a>.</li>
                                    </ul>

                                    <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2" id='p-c'>C. When Do These Terms Apply?</h3>
                                    <p>
                                        <strong>Summary:</strong> By using Printfuse, you are agreeing to all the terms below.
                                    </p>
                                    <p>By using the Service, You acknowledge that You accept and agree to be bound by these Terms of Service. If You do not agree to these Terms of Service, You may not access the Service, and if You are already registered, cancel Your account.</p>

                                    <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2" id='p-d'>D. Can These Terms Be Modified?</h3>
                                    <p>
                                        <strong>Summary:</strong> We can change these Terms of Service, and if the changes are big, we’ll let you know.
                                    </p>
                                    <p>Printfuse reserves the right to revise the Terms of Service from time to time and the most current version will always be posted on our Website. If a revision, in our sole discretion, is material we will notify You. The “Last Updated” date at the top of the Terms of Service reflects the date of the last modification. Your use of the Service following the effective date of any modifications to the Terms of Service will constitute your acceptance of such modification. If You do not agree to the new Terms of Service, You should stop using the Service and cancel Your account.</p>
                                    <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2" id='p-e'>E. What Is the Scope of Printfuse’s Services?</h3>
                                    <p>
                                        <strong>Summary:</strong> Printfuse is an online platform that allows you to create and connect your store to sell products with your content by working with various Print on Demand Providers from around the world. You focus on creating and selling, we handle the rest.
                                    </p>
                                    <p>
                                        Printfuse is an online marketplace for print on demand dropshipping. We partner with various Providers to offer You Products that You can customize with Your Content and sell to Customers. We also maintain and create integrations with different Sales Channels so that Your Products can be easily published to your Stores. When Your Products are purchased by Customers through a Sales Channel, We are notified and We start working with Providers who will print and deliver Your Products to the Customers.
                                    </p>
                                    <p>
                                        <strong>Summary:</strong> Printfuse is only a platform, we neither sell products nor do any printing ourselves.
                                    </p>
                                    <p>
                                        As the provider of the Service, Printfuse does not own, create, sell, resell, provide, control, manage, offer, deliver, or supply any Products or do any printing. Providers alone are responsible for their Products, the quality of the print and delivery of Your Product. If you have an issue with the Product, please see Section J below.
                                    </p>
                                    <p>
                                        <strong>Summary:</strong> Using Printfuse does not make you an employee of Printfuse.
                                    </p>
                                    <p>
                                        If You choose to use the Service to sell Your Products, Your relationship to Printfuse is limited to being that of a client and not an employee, agent, joint ventures, or partner of Printfuse for any reason. You act exclusively on your own behalf and for your own benefit, and not on behalf or for the benefit, of Printfuse.
                                    </p>
                                    <p>
                                        <strong>Summary:</strong> Printfuse is not responsible for your choice of Print Provider and the final product. We expect you to do all of your own homework on who best to work with.
                                    </p>
                                    <p>
                                        While We facilitate the resolution of disputes and provide necessary guidelines for the creation of Your Products, Printfuse has no control over and does not guarantee the final quality of Your Product. Nor do we make any promises that Your Product will look like Your Mockup. Print Providers differ in their printing technology, product offerings, and operations. Users are expected to do their own due diligence in selecting the most suitable Provider for creating Users’ Products.
                                    </p>
                                    <p>
                                        <strong>Summary:</strong> Printfuse will often link to and suggest other helpful services and sites that will help you successfully run your online shop. These are not our services and are not endorsements by us of their quality. Exercise your own judgement.
                                    </p>
                                    <p>
                                        Printfuse may give You access to link to various external resources or third-party services (“Third Party Sites”). Printfuse does not endorse any of these Third Party Sites and does not control them in any manner. These Third Party Sites may be governed by their own terms of service and policies. Printfuse is not liable or responsible for the accuracy of such services or their content, or products, and You need to take appropriate steps to determine whether accessing a Third Party Site is appropriate, and to protect Your personal information and privacy on such Third Party Site.
                                    </p>
                                    <p>
                                        <strong>Summary:</strong> We don’t guarantee that our website will be online 100% of the time. Due to the nature of product development and the Internet, our website may have periodic downtimes.
                                    </p>
                                    <p>
                                        Due to the nature of the Internet, Printfuse cannot guarantee uninterrupted access and availability of the Printfuse Service. We reserve the right to restrict the availability of our Service or certain features if necessary in view of capacity limits or the integrity of our servers. We also reserve the right to restrict access when performing maintenance measures to ensure the proper working and functioning of the Printfuse Service. Printfuse can improve or modify its Service and may introduce new offerings from time to time.
                                    </p>

                                    <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2" id='p-f'>F. Who Can Access Printfuse and Use the Service?</h3>
                                    <p>
                                        <strong>Summary:</strong> You have to be at least 18 years old to use our service and if you are signing up on behalf of someone else, you are acknowledging that you have the right to do so. Certain features of our platform may have additional terms other than those here.
                                    </p>
                                    <ul>
                                        <li>You must be at least 18 years old and able to access and use the Service or to create a Printfuse account. By accessing and using our Service, you represent and warrant that you are 18 years of age or older and have the legal capacity and authority to enter into a contract.</li>
                                        <li>If you open an account on behalf of a company, organization, or other entity, then “You” includes you and that entity, and You represent and warrant that You are authorized to grant all permissions and licenses provided in this Terms of Service and bind the entity to these Terms of Service, and that you agree to these Terms of Service on the entity’s behalf.</li>
                                        <li>The access to or use of certain areas and features of the Service may be subject to separate policies, standards or guidelines, or may require that you accept additional terms and conditions. If there is a conflict between these Terms of Service and the terms and conditions applicable to a specific area or feature of the Printfuse Service, the more specific terms and conditions will apply, unless specified otherwise.</li>
                                        <li>To use our Service, You have to register an account. Details on how to do so are provided in the next section.</li>
                                        <li>You understand and agree that You are responsible for paying any and all applicable taxes that may be due as a result of your use of the Service.</li>
                                    </ul>

                                    <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2" id="p-g">G. What Are the Rules for Creating and Maintaining an Account?</h3>
                                    <p>
                                        <strong>Summary:</strong> We have certain requirements that you must abide by when you create an account with Us.
                                    </p>
                                    <ul>
                                        <li>To create a Printfuse Account You may be required to provide Printfuse with certain personal information, which may include your full legal name, current address, phone number, email address, and payment information. This information will be held and used in accordance with Printfuse’s <a href="#">Privacy Policy</a>. You agree that You will supply accurate and complete information to Printfuse, and that You will update that information promptly after it changes. You further agree that:</li>
                                        <ul>
                                            <li>You shall not create an account or access the Service if You are under the age of 18;</li>
                                            <li>You shall not have more than one account at any given time, and shall not create an account using a false identity or information, or on behalf of someone other than yourself or your entity, which has provided you authorization to create the account on behalf of it;</li>
                                            <li>You shall not have an account or use the Service if You have been previously removed by the Company;</li>
                                            <li>We may reject your registration or cancel an existing registration for any reason at our sole discretion.</li>
                                            <li>We will use the email address you provide as the primary method of communication.</li>
                                            <li>Any harassment or insult towards Printfuse employees may result in immediate account termination.</li>
                                            <li>You are solely responsible for keeping your account credentials secure and you may not disclose your credentials to any third party. You must immediately notify Printfuse if you have any reason to suspect that your credentials have been compromised, lost or stolen or in the case of any actual or suspected unauthorized use of your Printfuse Account. You are solely responsible for any and all activities performed through your Printfuse account. Printfuse will not be liable for any loss that You may incur as a result of someone else using Your username or password, either with or without Your knowledge. To the extent allowable by law, you shall be liable for any expenses and reasonable attorney’s fees for your failure to safeguard user and password information and/or promptly notify the Company about unauthorized use of your account or breach of your account information or password.</li>
                                        </ul>
                                        <li>Printfuse expressly disclaims any and all liability arising from revocation, cancellation, or suspension of your account for any reason. Your account and registration will terminate immediately upon your breach of any of the terms herein. You may terminate your account and participation as a registered user at any time from your Account Settings Personal Data  Delete my data. Before proceeding with deleting your account, make sure that you have disconnected your store from the respective sales channels, unsubscribed from the business plan and removed your registered credit card. Deleting your account is irreversible and you will not be able to access the data afterwards. Nonetheless, you will still be responsible for any and all pending orders and charges.</li>
                                        <li>You represent and warrant that you shall not conduct any Illicit Activity against Printfuse or any third party, including when using Printfuse Service or in your sales process to your own customers. Printfuse is at all times entitled to request any documents, information, or other materials reasonably necessary, including “Know Your Client” documentation, as well as conduct an interview to verify and ensure compliance of Printfuse Terms. Printfuse is at all times entitled at its sole discretion to suspend the provision of Printfuse Service entirely or in part, as well as withhold any sums payable by Printfuse to You if it reasonably believes that you have committed an Illicit Activity or otherwise breached the Printfuse Terms.</li>
                                    </ul>
                                    <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2" id="p-h">H. What Are Your Rights to Content on Printfuse?</h3>
                                    <p>
                                        <strong>Summary:</strong> All content you upload is owned by you. If we are notified or if we believe that the content is not yours, or if the content doesn’t meet our standards, we will remove it at our discretion. By uploading content to Printfuse, you allow us to use your content for things like marketing and education of Printfuse users.
                                    </p>
                                    <ul>
                                        <li>Printfuse grants You a limited, revocable, non-exclusive, non-transferable license to access and use the Service for Your own individual use subject to the other terms of this Terms of Service. All rights, title, and interest in and to the Service not expressly granted herein are reserved by the Company.</li>
                                        <li>Printfuse allows You to upload and post Content to our Website. Anything that You request to be printed, embroidered, or otherwise and/or reflected on the Products via the Services remains Your Content. You retain all rights in, other than the rights expressly granted to Printfuse below and any that you may grant to your Customers, and are solely responsible for Your Content.</li>
                                        <li>You can only post Content that is either Yours, or for which you have written authorization from the Content owner. You represent and warrant that any Content you post is owned by You or that You have authorization from the Content owner to post it. You will provide Printfuse with evidence of Your Intellectual Property Rights shall it be requested by Printfuse. More detailed information can be found in the Printfuse <a href="#">Intellectual Property Policy</a>. By posting Content, You accept those terms as well. If You believe that someone has improperly used Your copyrights or trademarks, please report it to us as set forth in the <a href="#">Intellectual Property Policy</a>.</li>
                                        <li>By using the Printfuse Service, You grant Printfuse a non-exclusive, transferable, sublicensable, royalty-free, and worldwide license to use, store, display, reproduce, save, modify, create derivative works, perform and distribute Your Content solely for the purpose of operating, developing, and promoting the Printfuse Service.</li>
                                        <li>As a condition of use of the Service, You agree not to use the Service for any purpose that is prohibited by these Terms of Service. You are responsible for all of your activity in connection with the Service and you represent and warrant that you shall abide by all local, state, national, and international laws and regulations and any applicable regulatory codes in connection with your use of the Services (including the provision of Your Content), purchase of Products, and marketing, sale, and support of Customers who purchase Your Products. You represent and warrant that:</li>
                                        <ul>
                                            <li>You shall not resell, rent, lease, loan, sublicense, distribute, or otherwise transfer rights to the Service.</li>
                                            <li>You shall not modify, reverse engineer, decompile or disassemble the Service.</li>
                                            <li>You shall not copy, adapt, alter, modify, translate, or create derivative works of the Service without written authorization of Printfuse.</li>
                                            <li>You shall not permit other individuals to use the Service, including but not limited to shared use via a network connection, except under these Terms of Service.</li>
                                            <li>You shall not circumvent or disable any technological features or measures in the services that are designed for protection of intellectual property rights.</li>
                                            <li>You shall not use the Service in an attempt to, or in conjunction with, any device, program, or service designed to circumvent technological measures employed to control access to, or the rights in, a content file or other work protected by the copyright laws of any jurisdiction.</li>
                                            <li>You shall not use or access the Service to compile data in a manner that is used or usable by a competitive product or service.</li>
                                            <li>You shall not use your Account to advertise, solicit, or transmit any commercial advertisements, including chain letters, junk e-mail or repetitive messages to anyone.</li>
                                            <li>You shall not use your Account to engage in any illegal conduct.</li>
                                            <li>You shall monitor your Account to restrict use by minors, and You will deny access to children under the age of 13. You accept full responsibility for any unauthorized use of the Service by minors in connection with your Account and You are responsible for any use of your credit card or other payment instrument by minors.</li>
                                            <li>You shall not use the Service to order and resell blank Products without customizing them with your artwork.</li>
                                            <li>You shall not make any representations or warranties concerning the Products beyond what is permitted or expressly authorized by the Provider of such Products.</li>
                                            <li>Any such forbidden use shall immediately terminate Your license to the Service.</li>
                                        </ul>
                                        <li>Printfuse may use its discretion to remove Your Content from our Service, at any time, with or without prior notice to you, if it violates any of our Terms of Service or policies or is reported to be infringing on intellectual property rights of others. Printfuse is not obligated to monitor or police Your Content. In addition to intellectual property concerns, Content that contains any of the below is prohibited and may also lead to termination of Your account:</li>
                                        <ul>
                                            <li>Child Exploitation: You may not post or upload Content that exploits or abuses children, including but not limited to images or depictions of child abuse or sexual abuse, or that presents children in a sexual manner.</li>
                                            <li>Harassment, Bullying, Defamation and Threats: You may not post or upload Content that harasses, bullies, defames or threatens a specific individual.</li>
                                            <li>Hateful Content: You may not post or upload Content that condones or promotes violence against people based on race, ethnicity, color, national origin, religion, age, gender, sexual orientation, disability, medical condition or veteran status, promotes crimes and/or terrorism.</li>
                                            <li>Illegal Content: You may not post or upload Content that is obscene or that facilitates or promotes activities that go against the laws of the jurisdictions in which you operate or do business.</li>
                                            <li>Intellectual Property: You may not post or upload Content that infringes on the copyright or trademarks of others.</li>
                                            <li>Personal and Confidential Information: You may not post or upload any Content that contains personally identifiable information, sensitive personal information, or confidential information, such as credit card numbers, confidential national ID numbers, or account passwords unless you have consent from the person to whom the information belongs or who is otherwise authorized to provide such consent.</li>
                                            <li>Self-Harm: You may not post or upload Content that promotes self-harm.</li>
                                            <li>Terrorist Organizations: You may not post or upload Content that implies or promotes support or funding of, or membership in, a terrorist organization.</li>
                                        </ul>
                                        <li>When You use the Service, Printfuse reserves the right to view, monitor, and record activity without notice or further permission from You, to the fullest extent permitted by applicable law, although we have no obligation to do so. Any information obtained by monitoring, reviewing, or recording is subject to review by law enforcement organizations in connection with the investigation or prosecution of possible criminal activity involving use of the Service.</li>
                                        <li>Due to the nature of the Internet, Printfuse cannot guarantee uninterrupted access and availability of the Printfuse Service. We reserve the right to restrict the availability of our Service or certain features if necessary in view of capacity limits or the integrity of our servers. We also reserve the right to restrict access when performing maintenance measures to ensure the proper working and functioning of the Printfuse Service. Printfuse can improve or modify its Service and may introduce new offerings from time to time.</li>
                                    </ul>

                                    <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2" id="p-i">I. How do Payments Work on Printfuse?</h3>
                                    <p>
                                        <strong>Summary:</strong> Printfuse will only charge you fees on the order processing as platform fee and if you subscribe to a Printfuse Plan. No products are produced or shipped unless payment for those products has first been received via credit/debit, or from an existing Printfuse balance. Users have the option to change settings for when and how often they are charged. You cannot withdraw “wallet recharges and sales credits”.
                                    </p>
                                    <ul>
                                        <li>Unless Users subscribe to the Printfuse Plan, there are no fees or charges for the platform itself.</li>
                                        <li>To order samples of Your Products or to fulfill any Customer Orders, You must pay for the Order in advance. The price You pay will be the price of the Product plus shipping and taxes (if applicable). These prices can be found while adding a new product. Orders will not be processed until payment has been received by Printfuse.</li>
                                        <li>Printfuse cannot pull funds that you may have available in any Sales Channel. In order to process payments on Printfuse, You need to either have funds on your Printfuse balance or have your Printfuse account connected with an accepted method of payment. By default, Printfuse will charge funds for Orders from an existing Printfuse balance first, and if there is a remaining balance due then Printfuse will charge the funds via the method of payment connected to your Printfuse account.</li>
                                        <li>Printfuse charges Users when Orders are sent to processing.</li>
                                        <li>Printfuse may issue a coupon. A coupon is virtual money that can be used for paying for manual and sample orders. You may use a coupon only for a single order. Multiple coupons cannot be combined. Each coupon’s value is divided according to the total line items in the order. It cannot be converted to cash or withdrawn.</li>
                                        <li>If you have an issue with your order, contact Printfuse before disputing your claim with your credit card company. If you have filed a dispute with your credit card company, we will not be able to issue you a refund as well, and we cannot make any refunds or replacements until the dispute is resolved or cancelled. If there are Order issues and after an investigation Printfuse offers you a refund, this will be provided in the form of a Printfuse credit and will appear in your Printfuse balance.</li>
                                        <li>The user can withdraw the amount displayed in the "Withdrawal Amount" section, which may include earnings from both prepaid and COD orders collected by the courier company on behalf of the user. This is the case when user is using the snapstore feature.</li>
                                        <li>Users are responsible for paying any necessary sales tax on their transactions. Because of the nature of dropshipping businesses, taxes differ depending on who produces or sells the Product, and where it is delivered. Printfuse recommends that You consult with an outside tax accountant.</li>
                                        <li>Printfuse reserves the right to and may process and receive payments from you, as well as issue invoices to you from various Printfuse legal entities depending on multiple factors, such as, but not limited to Print Provider’s location, your location, region in which the transaction takes place and others.</li>
                                    </ul>

                                    <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2" id="p-j">J. How Does Printfuse Handle Order Related Issues?</h3>
                                    <p>
                                        <strong>Summary:</strong> If you have an issue with your Order, contact Printfuse Seller Support directly within 45 days of the product delivery. Provide a detailed description of your issue, accompanied by relevant photo or video evidence. After investigation, Printfuse will determine your eligibility for a free Reprint or Refund. Some issues do not qualify for reprinting, as outlined below.
                                    </p>
                                    <ul>
                                        <li>Printfuse does not hold inventory or handle manufacturing; quality is dependent on the selected Provider. Users must perform their own research when selecting a Provider and ensure Content meets print quality requirements.</li>
                                        <li>All conflict resolution is handled by Printfuse’s Seller Support team. Users must contact Printfuse Seller Support for all issues within 45 days of product delivery and cannot contact Print Providers directly. Providing relevant materials for investigation is essential.</li>
                                        <li>Products are unique and non-refundable. Once an Order is placed, modifications cannot be made. If shipment details are incorrect, or if the Customer ordered the wrong size or color, Printfuse is not responsible.</li>
                                        <li>Requests for changes to Order details, like address or delivery method, will be evaluated by Seller Support and may incur additional costs.</li>
                                        <li>Orders with updated artwork go through a Quality Control process. If issues are found, Sellers will be notified. If no response is received within 7 days, the order will proceed without eligibility for replacements or refunds.</li>
                                        <li>Production times are estimates and not guarantees. For unusually long processing or delivery times, contact Printfuse Seller Support. If an Order is lost during Shipment, Printfuse will investigate and may provide replacements.</li>
                                        <li>If a product is deemed faulty after investigation, replacements or refunds will be issued at Printfuse’s discretion as credit to your Printfuse balance.</li>
                                        <li>No refunds are provided for Orders in transit unless deemed lost by Seller Support.</li>
                                        <li>The Seller is responsible for ensuring that the delivery address is valid. If delivery fails due to an incorrect address, the Seller can request reshipment or cancellation, with associated costs borne by the Seller.</li>
                                        <li>If an Order has not been delivered in 30 days or 45 days (for international orders), contact Seller Support within 1 week to be eligible for a reprint/refund.</li>
                                        <li>If tracking indicates delivery but the item is not received, Printfuse may refuse a free Reprint or Refund, subject to investigation.</li>
                                        <li>Shipping delays due to carriers do not warrant refunds or Reprints unless the package is lost in transit.</li>
                                        <li>Requests for Refund or Reprint may be denied if the complaint relates to artwork issues or not following design guidelines.</li>
                                    </ul>

                                    <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2" id="p-k">K. Embroidery</h3>
                                    <p>
                                        <strong>Summary:</strong> To avoid issues, follow the embroidery guidelines closely.
                                    </p>
                                    <ul>
                                        <li>All designs for embroidery must conform to the Embroidery Design Guide.</li>
                                        <li>Images not meeting guidelines may be edited by Print Partners during digitization. The final product may differ, and replacements or refunds are not eligible.</li>
                                        <li>All new embroidery images have a 24-48 hour digitization period, delaying the entire order if multiple items are included.</li>
                                    </ul>

                                    <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2" id="l">L. Can You Use Printfuse’s Image and Name?</h3>
                                    <p>
                                        <strong>Summary:</strong> Printfuse takes intellectual property rights seriously. Respect our trademarks.
                                    </p>
                                    <ul>
                                        <li>Printfuse, including its logos and graphics, are registered trademarks that cannot be used without written consent.</li>
                                        <li>Images and icons from the brand kit may be used by partners with appropriate links to the Printfuse website.</li>
                                        <li>Printfuse reserves the right to request removal of any use of its images and icons at any time.</li>
                                    </ul>

                                    <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2" id="p-m">M. Limitation of Liability</h3>
                                    <p>
                                        <strong>Summary:</strong> Printfuse is not responsible for any losses resulting from your use of our Service, your purchases, or your marketing and sales of Products to Customers. This section limits our obligations and responsibilities.
                                    </p>
                                    <ul>
                                        <li>
                                            <strong>Limitation of Liability:</strong> To the fullest extent permitted by law, Printfuse and its affiliates are not liable for any indirect, incidental, consequential, or punitive damages, including loss of use, profits, or data, arising from your use of the Service. The total liability is limited to the fees paid to Printfuse or $100.00, whichever is greater, related to:
                                            <ul>
                                                <li>(a) The use of or inability to use the Service;</li>
                                                <li>(b) The cost of procuring substitute goods or services;</li>
                                                <li>(c) Statements or conduct of any third party on the Service;</li>
                                                <li>(d) Any other matter related to the Services.</li>
                                            </ul>
                                            Some jurisdictions do not allow these limitations, so they may not apply to you.
                                        </li>
                                        <li>
                                            You agree to indemnify and hold Printfuse and its affiliates harmless from any claims arising from your breach of these Terms, negligence, intentional acts, or violation of laws or rights of third parties.
                                        </li>
                                        <li>
                                            <strong>Warranty Disclaimers:</strong> The Service is provided "as is" and "as available." Printfuse does not guarantee the Service's security, availability, or freedom from errors or viruses. Your use is at your own risk.
                                        </li>
                                        <li>
                                            No advice or information obtained from Printfuse creates any warranty not expressly stated herein.
                                        </li>
                                        <li>
                                            You acknowledge that Printfuse is not liable for the conduct of third parties, including Providers, and that risks from such third parties rest entirely with you.
                                        </li>
                                        <li>
                                            Unless you have added Printfuse Connect to your Premium subscription, you are solely responsible for all communications and interactions with customers. Printfuse makes no warranty about the availability or quality of Products provided by Providers.
                                        </li>
                                    </ul>

                                    <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2" id="p-n">N. What if I Have Legal Issues with Printfuse?</h3>
                                    <p>
                                        <strong>Summary:</strong> If legal issues arise, you agree to contact us first. If unresolved, we will follow professional arbitration procedures. Non-arbitrable issues will be handled in Noida, UP.
                                    </p>
                                    <ul>
                                        <li>
                                            If a dispute arises, you agree to first contact Printfuse Seller Support to attempt resolution. If unresolved, parties will seek to mediate before arbitration, which will follow the Arbitration and Conciliation Act, 1996.
                                        </li>
                                        <li>
                                            The arbitration will be conducted by a sole arbitrator mutually appointed, with the seat of arbitration in Noida, UP, India. The arbitrator's award will be final and enforceable in Indian courts.
                                        </li>
                                        <li>
                                            Any claim not arbitrable will be resolved exclusively in accordance with this section.
                                        </li>
                                        <li>
                                            These Terms are governed by the laws of Noida, Uttar Pradesh, and you submit to the personal jurisdiction of Noida courts for actions not subject to arbitration.
                                        </li>
                                        <li>
                                            You and Printfuse agree that no class or collective actions can be asserted; all claims must be brought individually.
                                        </li>
                                    </ul>

                                    <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2" id="p-o">O. Printfuse Plan</h3>
                                    <p>
                                        <strong>Summary:</strong> Printfuse has a monthly and yearly subscription plan that offers up to a 30% discount on all products. Both of these plans auto-renew and do not cancel if you uninstall the Printfuse app or disconnect your store. You have to cancel before the renewal date.
                                    </p>
                                    <ul>
                                        <li>
                                            <strong>Recurring Subscription:</strong> By subscribing to the Printfuse Plan, you will receive discounts of up to 30% on all Printfuse products, and the subscription will continue month-to-month or year-to-year until cancellation or termination. You must cancel your subscription before it renews each month/year in order to avoid billing for the next subscription period.
                                        </li>
                                        <li>
                                            Before subscribing to the plan, you will be able to preview and compare both prices. After subscribing, the Premium plan prices will be the only prices displayed to you throughout the catalog. All visible prices from this point forward will have the Premium plan discount applied.
                                        </li>
                                        <li>
                                            <strong>Billing:</strong> You can pay for the subscription by using a payment card or your Printfuse Balance. By starting your subscription, you authorize us to charge you a full subscription fee. You acknowledge that the amount charged may vary from time to time for reasons that may include promotional offers or changes in the subscription plan. You authorize us to charge your payment card or Printfuse Balance for these amounts.
                                        </li>
                                        <li>
                                            Printfuse reserves the right to adjust pricing for our service or any components thereof in any manner and at any time as we may determine in our sole and absolute discretion. Any price changes to your service will take effect following a notice by email to you.
                                        </li>
                                        <li>
                                            You will be charged for the subscription fee immediately, and the charges will auto-renew every month for monthly subscriptions and every year for yearly subscriptions unless you cancel the subscription.
                                        </li>
                                        <li>
                                            We automatically charge your chosen payment method every month or every year on the calendar day corresponding to the commencement of your subscription. We reserve the right to change the timing of the charge if your payment method has been declined. For example, if your subscription started on August 31, we may charge the next fee on September 30.
                                        </li>
                                        <li>
                                            In case you switch from a monthly (not cancelled) to a yearly subscription, your monthly subscription will be terminated immediately, and the yearly subscription will be activated starting from the switching date. The amount charged for unused days within your monthly subscription will be proportionally deducted from the yearly subscription fee.
                                        </li>
                                        <li>
                                            In case you switch from a monthly (cancelled) but still active subscription to a yearly subscription, your monthly subscription will be terminated immediately, and the yearly subscription will be activated starting from the switching date. The full amount for a yearly subscription will be charged in this case.
                                        </li>
                                        <li>
                                            <strong>Cancellation:</strong> Subscription fee charges are non-refundable, and there are no refunds or credits for partially used subscription periods. After a cancellation, you will continue to have access to the discounts until the end of your billing cycle.
                                        </li>
                                        <li>
                                            To cancel your subscription, access the Payment section by clicking on the settings icon in the upper right-hand corner of your account page. After cancellation, the subscription will be active until its expiration date but will not be auto-renewed.
                                        </li>
                                        <li>
                                            Please note that you must unsubscribe before the next billing cycle to avoid charges for the following month or year. Printfuse subscription fee charges cannot be refunded.
                                        </li>
                                        <li>
                                            Printfuse Pro does not automatically get cancelled when you disconnect your Printfuse store or uninstall the Printfuse app. It must be manually cancelled from the Payment section to stop all subscription charges.
                                        </li>
                                    </ul>

                                    <section>
                                        <h2>O. Printfuse Plan</h2>
                                        <p><strong>Summary:</strong> Printfuse has a monthly and yearly subscription plan that offers up to a 30% discount on all products. Both of these plans auto-renew and do not cancel if you uninstall the Printfuse app or disconnect your store. You have to cancel before the renewal date.</p>

                                        <h3>Recurring Subscription</h3>
                                        <ul>
                                            <li>By subscribing to the Printfuse Plan, you will receive discounts of up to 30% on all Printfuse products and the subscription will continue month-to-month or year-to-year until cancellation or termination. You must cancel your subscription before it renews each month/year in order to avoid billing for the next subscription period.</li>
                                            <li>Before you subscribe to the plan, you will be able to preview and compare both prices. After subscribing, the Premium plan prices will be the only prices displayed to you throughout the catalog. All visible prices from this point forward will have the Premium plan discount applied.</li>
                                        </ul>

                                        <h3>Billing</h3>
                                        <ul>
                                            <li>You can pay for the subscription by using a payment card or your Printfuse Balance. By starting your subscription, you authorize us to charge you a full subscription fee. You acknowledge that the amount charged may vary from time to time for reasons that may include promotional offers, or changes in subscription plan. You authorize us to charge your payment card or Printfuse Balance for these amounts.</li>
                                            <li>Printfuse reserves the right to adjust pricing for our service or any components thereof in any manner and at any time as we may determine in sole and absolute discretion. Any price changes to your service will take effect following a notice by email to you.</li>
                                            <li>You will be charged for the subscription fee immediately and the charges will auto-renew every month for monthly subscriptions and every year for yearly subscriptions unless you cancel the subscription.</li>
                                            <li>We automatically charge your chosen payment method every month or every year on the calendar day corresponding to the commencement of your subscription. We reserve the right to change the timing of the charge if your payment method has been declined. In the event your subscription is started on a day not contained in a given month, we will charge your payment method on a day we deem appropriate. For example, if your subscription started on August 31, we will likely charge the next fee on September 30.</li>
                                            <li>In case you switch from a monthly (not cancelled) to a yearly subscription, your monthly subscription will be terminated immediately and the yearly subscription will be activated starting from the switching date. The amount charged for unused days within your monthly subscription will be proportionally deducted from the yearly subscription fee. For instance, if you switch from a monthly to an yearly subscription plan halfway through the month, you will be charged the yearly subscription, ₹2499, minus the sum for unused days in the month, in this case ₹149 (half of the month), which means the billed amount will be ₹2350.</li>
                                            <li>In case you switch from a monthly (cancelled) but still active to a yearly subscription, your monthly subscription will be terminated immediately and the yearly subscription will be activated starting from the switching date. However, the full amount for a yearly subscription will be charged in this case.</li>
                                        </ul>

                                        <h3>Cancellation</h3>
                                        <ul>
                                            <li>SUBSCRIPTION FEE CHARGES ARE NON-REFUNDABLE AND THERE ARE NO REFUNDS OR CREDIT FOR PARTIALLY USED SUBSCRIPTION PERIODS. After a cancellation, you will continue to have access to the discounts throughout the end of your billing cycle.</li>
                                            <li>To cancel your subscription, access the Payment section by clicking on the setting icon in the upper right-hand corner of your account page, for a dropdown menu. After cancellation, the subscription will be active until its expiration date but will not be auto-renewed.</li>
                                            <li>Please note that you must unsubscribe before the next billing cycle to avoid charges for the following month or year. Printfuse Subscription fee charges cannot be refunded.</li>
                                            <li>Printfuse Pro does not automatically get cancelled when you disconnect your Printfuse store or uninstall the Printfuse app. It must be manually cancelled from the Payment section to stop all subscription charges.</li>
                                        </ul>
                                    </section>

                                    <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2" id="p-p">P. Printfuse FX Rate</h3>
                                    <p><strong>Summary:</strong> You may select a non-INR currency as billing currency for Your orders. When You select a non-INR currency Printfuse will use Printfuse FX Rate to convert INR to Your chosen currency. By selecting a non-INR currency, refunds for orders will be returned to You in the selected non-INR currency.</p>
                                    <ul>
                                        <li>You may select a non-INR currency as billing currency for Your orders on Printfuse.</li>
                                        <li>When You select a non-INR currency for Your order, Printfuse will use foreign exchange rate/-s (Printfuse FX Rate) to convert INR to Your chosen currency and to determine the total payment for products, shipping, and any other applicable costs in Your selected currency.</li>
                                        <li>The applied Printfuse FX Rate/-s may not match the foreign exchange rates published online or in other financial databases. These rates are generally inter-bank rates not available to end customers. Due to foreign exchange rate fluctuations, Printfuse will always try to apply the most efficient Printfuse FX Rate/-s.</li>
                                        <li>Printfuse reserves the right, at its sole discretion, to change Printfuse FX Rate/-s at any time.</li>
                                        <li>If You select a non-INR currency as a billing currency, refunds for orders will be returned to Your credit or debit card in the selected non-INR currency. We will use the same Printfuse FX Rate that was used for the original payment for Your order to calculate the refund, including any fees.</li>
                                        <li>Please note, if You select to use a non-INR currency, some limitations may apply. For example, the use of coupons and Printfuse’s INR Balance will be disabled, but Your subscription will still be charged in USD. You will be notified about any applied limitations when You select a non-INR currency.</li>
                                        <li>You may stop using non-INR currency at any time and select USD as the billing currency for Your orders.</li>
                                    </ul>

                                    <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2" id="p-q">Q. Printfuse SnapStore</h3>
                                    <ul>
                                        <li>This Section is applicable to Printfuse SnapStore Service and shall apply in addition to other Sections of these Terms of Service. In case of a discrepancy, this Section shall prevail.</li>
                                        <li>You may opt to use Printfuse SnapStore Service to create Your Snap Store. Your SnapStore will be accessible through a dedicated link once you create and publish Your Store.</li>
                                        <li>Printfuse will act as the seller for the Products. The determination of price, availability, accessibility, distribution, and sales terms for the Products rests solely with Printfuse. We are authorized to handle payments, manage refund inquiries, and customer support. All sales and associated data, including customer data, are solely owned by Printfuse.</li>
                                        <li>You grant Printfuse a nonexclusive, worldwide, fully paid-up, transferable, sublicensable license under Your Intellectual Property Rights to copy, display, distribute, and modify the content You upload to Printfuse (including all related images, text, and content).</li>
                                        <li>All listings must meet reasonable production standards, and all advertisements or promotional descriptions must be accurate and correct. Listings must not include content concerning non-Printfuse activities, events, products, services, or promotions.</li>
                                        <li>For Customer orders, You will receive a royalty payment based on the difference between the sale price and applied costs, including production costs, returns, taxes, Printfuse markup, and other applicable costs ("Royalty"). You are responsible for setting Your Royalty, which will be included in the final price charged to End-Users. Shipping costs and applicable taxes will be applied on top of the final price.</li>
                                        <li>Royalty payments are due the following calendar month, provided that Printfuse has received final payment from Customers. Royalty payments are not due for refunded or disputed sales or sales that do not result in gross profit for Printfuse.</li>
                                        <li>Printfuse may charge GST on top of Your listing price if required by law. Printfuse will deduct applicable taxes from any sums payable to You. You are responsible for paying additional taxes in your jurisdiction and exercising rights under any tax treaties applicable to You.</li>
                                        <li>Additional information may be required by Printfuse for reporting purposes, and payment may be withheld until such information is provided.</li>
                                    </ul>
                                </div>
                                <div className="page-footer panel">
                                    <p className="fs-7 opacity-60 m-0">Last updated: 1st October 2024</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsOfServices